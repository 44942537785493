import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { useLocation } from "react-router-dom";

import AdminNavbarLinks from "./AdminNavbarLinks";
import { useSidebar } from "../../context/SidebarContext";
import { userStorage } from "utils/helper";
import { IoChevronBackOutline } from "react-icons/io5";
import BackButton from "components/BackButton";

export default function AdminNavbar(props) {
  const location = useLocation();
  let path = location.pathname;

  const [scrolled, setScrolled] = useState(false);
  const [mediaQuery] = useMediaQuery("(max-width: 2100px)");
  const { sidebarOpen } = useSidebar();
  let loginObject = userStorage.get();
  const { brandText, routeInfo } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("gray.700", "gray.200");
  let secondaryText = useColorModeValue("gray.400", "gray.200");
  let navbarPosition = "absolute";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(21px)";
  let navbarShadow = "none";
  let navbarBg = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
      navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
      );
    }
  if (props.secondary) {
    navbarBackdrop = "none";
    navbarPosition = "absolute";
    mainText = "white";
    secondaryText = "white";
    secondaryMargin = "22px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      borderColor={navbarBorder}
      filter={navbarFilter}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      borderRadius="16px"
      display="flex"
      // left={document.documentElement.dir === "rtl" ? "30px" : ""}
      right={{
        md: "",
        xl: "0px",
      }}
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      // mx={{
      //   sm: sidebarOpen ? "45px" : "0px",
      //   md: sidebarOpen ? "45px" : "-10px",
      //   xl: sidebarOpen ? "0px" : "",
      // }}
      // me={{
      //   sm: sidebarOpen ? "0px" : "",
      //   md: sidebarOpen ? "0px" : "",
      // }}
      mt={secondaryMargin}
      pb="8px"
      // px={"30px"}
      // ps={{
      //   sm: sidebarOpen ? "45px" : "20px",
      //   md: sidebarOpen ? "38px" : "8px",
      //   xl: mediaQuery ? "0px" :sidebarOpen ? "2%" : "2%",
      //   "2xl": sidebarOpen ? "20px" : "20px"
      // }}
      ms={{
        sm: "16px",
        md: "25px",
      }}
      pe={{
        sm: mediaQuery ? "10px" : "",
        md: mediaQuery ? "10px" : "",
        xl: mediaQuery ? "20px" : "",
      }}
      pt="12px"
      top="18px"
      w={{
        sm: "95.5%",
        md: "96%",
        xl: sidebarOpen ? "calc(100vw - 300px)" : "90.5%",
      }}
    >
      <Flex
        w="100%"
        // flexDirection={{
        //   sm: "column",
        //   md: "row",
        // }}
        alignItems={{ xl: "center" }}
      >
        {
        // path.includes("client") ||
        // path.includes("agency") ||
        // path.includes("users") ? (
        //   <Breadcrumbs />
        // ) : 
        (
          <HStack gap="8px">
            <BackButton routeInfo={routeInfo} icon={<IoChevronBackOutline />} routes={path} />
            <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              // marginLeft={{
              //   sm: sidebarOpen ? "" : "30px",
              //   md: sidebarOpen ? "" : "30px",
              // }}
              className="Navbar"
            >
              {brandText}
            </Link>
          </HStack>
        )}

        <Box ms="auto" w={{ sm: "98%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
