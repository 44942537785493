import axios from "axios";
import { userStorage } from "utils/helper";
import { format } from "date-fns";
import { useUserContext } from "context/UserContext";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_ENDPOINT_V2 = process.env.REACT_APP_API_ENDPOINT_V2;
const API_ENDPOINT_ORIGIN_SERVICE = process.env.REACT_APP_SERVICES_ENDPOINT;

const SAS_URL_GENERATOR = process.env.REACT_APP_SAS_URL_GENERATOR;
const AUTH_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;
const SERVICES_ENDPOINT = process.env.REACT_APP_SERVICES_ENDPOINT;
const headerHandler = () => {
  const storedLoginData = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(storedLoginData);
  const token = JSON.parse(localStorage.getItem("token"));

  const authToken = loginData ? loginData.token : token;
  // console.log("Auth token: " + authToken)

  return {
    authorization: authToken,
    "Content-Type": "application/json",
  };
};

// No Auth APIs
export const loginAPI = (payload) => {
  const url = `${AUTH_URL}/users/login`;
  return axios.post(url, payload);
};

export const sendFPOTP = (email, otpType) => {
  const url = `${AUTH_URL}/users/emailotp/${email}/${otpType}`;
  return axios.get(url);
};

export const resendFPOTP = (oid, otpType) => {
  const url = `${AUTH_URL}/users/resendemailotp/${oid}/${otpType}`;
  return axios.get(url);
};

export const validateOTP = (otp, oid) => {
  const url = `${AUTH_URL}/users/validateotp?otp=${otp}&oid=${oid}`;
  return axios.get(url);
};

export const setNewPassword = (oid, password) => {
  const url = `${AUTH_URL}/users/setnewpassword/${oid}`;
  return axios.post(url, { password });
};

export const getBackendHealth = () => {
  const url = `${AUTH_URL}/misc/ping`;
  return axios.get(url);
};

//Inspection Apis (Gets the array of inspection ids.)
// export const getInspection = (payload) => {
//   console.log("coming to get Inspections");
//   let url = `${API_ENDPOINT}/inspections?`;
//   let isDoubleQuery = false;
//   if (
//     payload?.warehouseId !== null &&
//     payload?.warehouseId !== "" &&
//     payload?.warehouseId !== undefined
//   ) {
//     url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
//     isDoubleQuery = true;
//   }
//   if (payload.start_dt && payload.end_dt) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
//   }
//   if (payload.query.auditorName.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.auditorName.map((item) => `"${item}"`);
//     url = `${url}auditor_names=[${data}]`;
//   }
//   if (payload.query.cmName.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.cmName.map((item) => `"${item}"`);
//     url = `${url}cm_names=[${data}]`;
//   }
//   if (payload.query.originLocation.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.originLocation.map((item) => `"${item}"`);
//     url = `${url}locations=[${data}]`;
//   }
//   if (isDoubleQuery) url += "&";

//   if (payload?.page) {
//     url = `${url}page=${payload.page + 1}&size=11&order=DESC`;
//   }

//   const headers = headerHandler();
//   return axios.get(url, { headers });
// };
export const previewGenerator = (payload) => {
  let { inspectionId, jobId, taskId, status } = payload;
  const url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}/items-review`;

  const headers = headerHandler();
  return axios.post(url, payload, { headers });
};

export const getSingleInspection = (payload) => {
  let url = `${API_ENDPOINT}/inspections/${payload.payload}`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getInspection = (payload) => {
  let url = `${API_ENDPOINT}/inspections?`;
  let isDoubleQuery = false;
  // Check if payload is defined
  if (payload) {
    if (payload?.clientIds) {
      url = `${url}clients=${payload?.clientIds}`;
      isDoubleQuery = true;
    }
    if (
      payload?.warehouseId !== null &&
      payload?.warehouseId !== "" &&
      payload?.warehouseId !== undefined
    ) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}origins=${payload.warehouseId}`;
    }
    if (payload.start_dt && payload.end_dt) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
    }
    if (payload.query?.auditorId?.length > 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}auditor_ids=${payload.query?.auditorId?.join(",")}`;
    }
    if (payload.query?.cmName?.length > 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}cm_names=${payload.query?.cmName?.join(",")}`;
    }
    if (payload.query?.originLocation?.length > 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}locations=${payload.query?.originLocation?.join(",")}`;
    }
    if (isDoubleQuery) url += "&";
    if (payload?.page >= 0) {
      if (payload?.size) {
        url = `${url}page=${payload.page + 1}&size=${payload.size}&order=DESC`;
        isDoubleQuery = true;
      } else {
        url = `${url}page=${payload.page + 1}&size=10&order=DESC`;
        isDoubleQuery = true;
      }
    }
  }

  if (payload?.status?.length > 0) {
    // console.log("Adding status to URL:", payload.status);
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;

    url = `${url}status=${payload.status?.join(",")}`;
    // console.log(url);
  }

  const headers = headerHandler();

  return axios.get(url, { headers });
};

export const getAddFilterDropdown = (payload) => {
  let url = `${API_ENDPOINT}/inspections?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId &&
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload?.query) {
    if (payload?.query?.auditorId && payload?.query?.auditorId?.length !== 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}auditor_ids=${payload.query.auditorId?.join(",")}`;
    }
    if (payload?.query?.cmName && payload.query.cmName.length !== 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}cm_names=${payload.query.cmName?.join(",")}`;
    }
    if (
      payload?.query?.originLocation &&
      payload.query.originLocation.length !== 0
    ) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}locations=${payload.query.originLocation?.join(",")}`;
    }
  }
  if (
    payload?.status &&
    payload?.status !== "" &&
    payload?.status?.length > 0
  ) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}status=${payload.status?.join(",")}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getWarehouses = (payload) => {
  let url = `${API_ENDPOINT}/origins?`;
  let isDoubleQuery = false;
  if (payload?.clientIds) {
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload.warehouseId !== undefined
  ) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}origins=${payload.warehouseId}`;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location?.join(",")}`;
    isDoubleQuery = true;
  }

  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities?.join(",")}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const fetchSASUrl = (payload) => {
  const url = SAS_URL_GENERATOR;
  return axios.post(url, payload);
};

export const fetchSummarySheet = (payload) => {
  let url = `${API_ENDPOINT}/extras/summary-report`;
  const headers = headerHandler();

  return axios.post(url, payload, { headers, responseType: "arraybuffer" });
};

export const fetchReportBlob = async (payload) => {
  const res = await axios.get(payload);
  const blob = await res.blob();
  return blob;
};

export const fetchUserProfileData = (payload) => {
  let url = new URL(`${API_ENDPOINT}/users/profiles`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const sendReport = (payload) => {
  const url = `${API_ENDPOINT}/extras/sendMail`;

  const headers = headerHandler();
  return axios.post(url, payload, { headers });
};

//Notification Apis
export const getNotifications = (payload) => {
  const url = `${API_ENDPOINT}/notifications/user/${payload.userId}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};

// update all user notifications
export const markReadAllNotifications = (payload) => {
  const url = `${API_ENDPOINT}/notifications/user/${payload.userId}`;

  const headers = headerHandler();
  return axios.put(url, { headers });
};

export const readNotification = (payload) => {
  const url = `${API_ENDPOINT}/notifications/${payload}`;
  const headers = headerHandler();
  return axios.put(url, { headers });
};

export const getAllItems = (payload) => {
  const url = `${API_ENDPOINT}/inspections/${payload?.join(",")}/items`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};
//Analytical Dashboard Apis

export const getAnalyticsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Bar Chart Data
export const getBarChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/inspections-insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }
  if (payload.unit) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}type=${payload.unit}`;
  }
  if (payload.clients) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}clients=${payload.clients}`;
  }
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Doughnut Chart Data
export const getDoughnutChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/commodity-health-insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Quantity Insights
export const getQuantityInsightsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/quantity-insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Warehouse Insights
export const getWarehouseInsightsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/origin-insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined &&
    payload.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Line Chart Data
export const getPortfolioLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/portfolio?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getInspectionLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/inspections?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getQuantityLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/quantity?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getMapChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/geo-insights?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Commodities Data
export const getCommoditiesData = (payload) => {
  let url = `${API_ENDPOINT}/inspections/commodities?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Profile dropdown Account page apis

export const getPreDefinedURL = (payload) => {
  let url = `${API_ENDPOINT}/extras/pre-signed-url`;
  const headers = headerHandler();

  return axios.post(url, { fileName: payload }, { headers });
};

export const uploadProfileImage = (payload) => {
  let url = payload.uploadURL;
  var headers = {
    "x-ms-blob-type": "BlockBlob",
    "x-ms-date": new Date().toUTCString(),
  };

  var response = fetch(url, {
    method: "PUT",
    headers: headers,
    body: payload.fileBlob,
    muteHttpExceptions: true,
  });
  return response;
};

export const getUserProfile = (payload) => {
  let url = `${API_ENDPOINT}/users/profile/${payload.id}`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const updateUserProfile = (payload) => {
  let url = `${API_ENDPOINT}/users/profile/${payload.id}`;

  const headers = headerHandler();
  return axios.put(url, payload.payload, { headers });
};

export const userPasswordReset = (payload) => {
  let url = `${API_ENDPOINT}/users/reset-password`;

  const headers = headerHandler();
  return axios.put(url, payload.passwordData, { headers });
};

export const getSearchData = (payload) => {
  let url = `${API_ENDPOINT}/search/inspections?`;
  if (payload.query !== null && payload.query !== "") {
    url = `${url}q=${payload.query}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getWarehouseScore = (payload) => {
  let url = `${API_ENDPOINT}/analytics/warehouse-score?status=approved&`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=${payload.warehouseId}`;
    isDoubleQuery = true;
  }
  if (payload?.clientIds) {
    if (isDoubleQuery) url += "&";
    url = `${url}clients=${payload?.clientIds}`;
    isDoubleQuery = true;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=${payload.commodities}`;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=${payload.location}`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

//Roles & Permission APIs

export const userRoles = (query) => {
  let url = `${API_ENDPOINT}/user-roles`;
  if (query) {
    url = `${API_ENDPOINT}/user-roles${query}`;
  }
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const editRole = (roleId) => {
  // let url = `${API_ENDPOINT}/roles/${roleId}`;/
  let url = `${API_ENDPOINT}/roles`;

  if (roleId) {
    url = `${API_ENDPOINT}/roles/${roleId}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const permissionsData = () => {
  let url = `${API_ENDPOINT}/perms-set`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const postUserData = (payload) => {
  let url = `${API_ENDPOINT}/roles`;
  const header = headerHandler();
  return axios.post(url, payload, { headers: header });
};

export const rolePermissionData = (roleId) => {
  let url = `${API_ENDPOINT}/role-perms-set/roles/${roleId}`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const userDetails = async (userIds) => {
  const filteredUserIds = userIds.filter((id) => id !== undefined);
  if (filteredUserIds.length == 0) return;

  let promiseArray = filteredUserIds.map((id) => {
    let url = `${API_ENDPOINT}/users/profile/${id}`;
    const header = headerHandler();
    return axios.get(url, { headers: header });
  });
  const res = await Promise.all(promiseArray);
  return res;
};

export const updateRoleData = (payload) => {
  const { roleId, newRoleObject } = payload;
  let url = `${API_ENDPOINT}/roles/${roleId}`;
  const header = headerHandler();
  return axios.put(url, newRoleObject, { headers: header });
};

export const userAssignedData = (roleId) => {
  let url = `${API_ENDPOINT}/user-roles/roles/${roleId}`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deleteRole = (payload) => {
  const { roleId, newRoleId } = payload;

  const assignParam = newRoleId ? `?assign=${newRoleId}` : "";
  let url = `${API_ENDPOINT}/roles/${roleId}${assignParam}`;
  const header = headerHandler();
  return axios.delete(url, { headers: header });
};

export const getSingleUsers = (roleId) => {
  let url = `${API_ENDPOINT}/user-roles/roles/${roleId}/assigned/active`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deactivateRole = (payload) => {
  const { roleId, newRoleInfo } = payload;
  let url = `${API_ENDPOINT}/roles/deactivate/${roleId}`;
  const headers = headerHandler();

  if (newRoleInfo) return axios.put(url, newRoleInfo, { headers });

  return axios.put(url, {}, { headers });
};

export const activateRole = (payload) => {
  const { roleId, isRevokeAssignments } = payload;
  const revokeInfo = { isRevokeAssignments };
  let url = `${API_ENDPOINT}/roles/activate/${roleId}`;
  const headers = headerHandler();

  return axios.put(url, revokeInfo, { headers });
};

// this function is used in multiple modules like Agency, client, job, etc
export const getWarehouseDetails = (payload) => {
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins?currentManagerId=${payload}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};
export const getWarehouseData = () => {
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};
//Job Management
export const getOneJob = (payload) => {
  let url = `${API_ENDPOINT}/inspections/${payload.inspectionId}/jobs/${payload.jobId}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const createInspection = (payload) => {
  let url = `${API_ENDPOINT}/inspections`;
  const header = headerHandler();
  return axios.post(url, payload, { headers: header });
};

export const createJob = (payload) => {
  const { jobPayload, inspectionId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs`;
  const header = headerHandler();
  return axios.post(url, jobPayload, { headers: header });
};

export const createTasks = (payload) => {
  const { taskPayload, inspectionId, jobId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks`;
  const header = headerHandler();
  return axios.post(url, taskPayload, { headers: header });
};

export const getWorkflowList = (payload) => {
  let url = `${API_ENDPOINT}/workflows`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const fetchWorkflowData = (payload) => {
  const { workflowId } = payload;
  let url = `${API_ENDPOINT}/workflows/${workflowId}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};
export const getTasks = (payload) => {
  const { inspectionId, jobId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getMultipleTasks = async (payload) => {
  const { multipleJobIdArr } = payload;
  let promiseArray = multipleJobIdArr.map((job) => {
    let url = `${API_ENDPOINT}/inspections/${job.inspectionId}/jobs/${job.jobId}/tasks`;
    const header = headerHandler();
    let res = axios.get(url, { headers: header });
    return res;
  });
  const res = await Promise.all(promiseArray);
  const data = res.map((obj) => {
    return obj.data.payload;
  });
  return data;
};

export const getOneInspection = (payload) => {
  const { inspectionId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getUserDetails = async (userIds) => {
  const filteredUserIds = userIds.filter((id) => id !== undefined);
  if (filteredUserIds.length == 0) {
    return;
  }
  let promiseArray = filteredUserIds.map((id) => {
    let url = `${API_ENDPOINT}/users/profile/${id}`;
    const header = headerHandler();
    let res = axios.get(url, { headers: header });
    return res;
  });
  const res = await Promise.all(promiseArray);
  const data = res.map((obj) => {
    return obj.data.payload;
  });
  return data;
};

export const getMultipleUserDetails = async (payload) => {
  let promiseArrayOfArrs = payload.map((arr) => {
    return arr.map(async (id) => {
      let url = `${API_ENDPOINT}/users/profile/${clientId}`;
      const header = headerHandler();
      let res = await axios.get(url, { headers: header });
      return res;
    });
  });
  const res = await Promise.all(
    promiseArrayOfArrs.map(async (promiseArray) => {
      return Promise.all(promiseArray);
    })
  );
  const data = res.map((arr) => {
    return arr.map((obj) => {
      return obj.data.payload;
    });
  });
  return data;
};

export const getUserProfileImg = async (payload) => {
  let promiseArray = payload.map((avatarName) => {
    if (!avatarName) {
      return null;
    }
    let url = `${API_ENDPOINT}/extras/pre-signed-url`;
    const header = headerHandler();
    let res = axios.post(url, { fileName: avatarName }, { headers: header });
    return res;
  });
  const res = await Promise.all(promiseArray);
  const data = res.map((obj) => {
    return obj !== null ? obj.data.payload : "";
  });
  return data;
};

export const getAllUrls = async (payload) => {
  // console.log(payload);
  let promiseArray = payload?.map((fileName) => {
    if (!fileName) {
      return null;
    }
    let url = `${API_ENDPOINT}/extras/pre-signed-url`;
    const header = headerHandler();
    let res = axios.post(url, { fileName }, { headers: header });
    return res;
  });
  const res = await Promise.all(promiseArray);
  const data = {};
  res.forEach((obj, index) => {
    data[[payload[index]]] = obj.data.payload.url;
  });
  return data;
};

export const getMultipleAvatarUrls = async (payload) => {
  let promiseArrayOfArrs = payload.map((arr) => {
    return arr.map(async (avatarName) => {
      if (avatarName === null) {
        return null;
      }
      let url = `${API_ENDPOINT}/extras/pre-signed-url`;
      const header = headerHandler();
      let res = await axios.post(
        url,
        { fileName: avatarName },
        { headers: header }
      );
      return res;
    });
  });
  const res = await Promise.all(
    promiseArrayOfArrs.map(async (promiseArray) => {
      return Promise.all(promiseArray);
    })
  );
  const data = res.map((arr) => {
    return arr.map((obj) => {
      return obj !== null ? obj.data.payload : "";
    });
  });
  return data;
};

export const updateJobStatus = (payload) => {
  const { inspectionId, jobId, status } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}`;
  const headers = headerHandler();
  return axios.put(url, { status }, { headers });
};

export const deleteJob = (payload) => {
  const { inspectionId, jobId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}`;
  const headers = headerHandler();
  return axios.delete(url, { headers });
};

export const getAllJobs = (payload) => {
  const { query, customerId } = payload;
  let url = `${API_ENDPOINT}/customers/${customerId}/jobs`;
  if (query)
    url = `${API_ENDPOINT}/customers/${customerId}/jobs${query}&page=${
      payload.page + 1
    }&size=10&order=DESC`;
  else url = url + `?page=${payload.page + 1}&size=10&order=DESC`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const updateTask = (payload) => {
  const { taskPayload, inspectionId, jobId, taskId } = payload;

  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}`;
  const header = headerHandler();
  return axios.put(url, taskPayload, { headers: header });
};

export const deleteTasks = (payload) => {
  const { inspectionId, jobId } = payload;

  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks`;
  const header = headerHandler();
  return axios.delete(url, { headers: header });
};

export const updateJob = (payload) => {
  const { jobPayload, inspectionId, jobId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}`;
  const header = headerHandler();
  return axios.put(url, jobPayload, { headers: header });
};

export const getInspectionDetails = (payload) => {
  const { inspectionId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/jobs`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const updateInspection = (payload) => {
  const { inspectionPayload, inspectionId } = payload;

  let url = `${API_ENDPOINT}/inspections/${inspectionId}`;
  const header = headerHandler();
  return axios.put(url, inspectionPayload, { headers: header });
};

//Customer Management
export const getAllCustomers = (payload) => {
  let url = new URL(`${API_ENDPOINT}/customers`);
  if (payload) {
    const {
      fromDate,
      toDate,
      order,
      businessTypes,
      states,
      cities,
      isActive,
      page,
    } = payload;
    if (page || page === 0) {
      url.searchParams.append("page", page + 1);
      url.searchParams.append("size", 10);
    }
    if (fromDate && toDate) {
      url.searchParams.append("start_dt", format(fromDate, "yyyy-MM-dd"));
      url.searchParams.append("end_dt", format(toDate, "yyyy-MM-dd"));
    }
    if (order) url.searchParams.append("order", order);
    if (businessTypes && businessTypes.length > 0)
      url.searchParams.append("businessTypes", businessTypes?.join(","));
    if (states && states.length)
      url.searchParams.append("states", states?.join(","));
    if (cities && cities.length > 0)
      url.searchParams.append("cities", cities?.join(","));
    if (isActive !== undefined && isActive !== "" && isActive !== null)
      url.searchParams.append("isActive", isActive);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getCustomerUsers = (payload) => {
  const { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/users`);
  if (payload) {
    const { roleName } = payload;
    if (roleName) url.searchParams.append("rolename", roleName);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getClientsByCustomerId = (payload) => {
  const { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/clients`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getCustomerLinks = (payload) => {
  const { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/links`);
  const header = headerHandler();
  // console.log("id" + payload)
  return axios.get(url.toString(), { headers: header });
};

export const postCustomerData = (payload) => {
  let url = new URL(`${API_ENDPOINT}/customers`);
  const header = headerHandler();
  return axios.post(url.toString(), payload, { headers: header });
};

export const addCustomersAddress = (payload) => {
  // v1/customers/313be771-4511-4ca9-8690-aa9725e55c04/addresses
  let url = new URL(
    `${API_ENDPOINT}/customers/${payload.customerId}/addresses`
  );
  const header = headerHandler();
  return axios.post(url.toString(), payload.data, { headers: header });
};

export const getCustomerDetails = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getCustomersAddress = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/addresses`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getCustomerAddresses = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/addresses`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const updateCustomerData = (payload) => {
  const { customerData, customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}`);
  const header = headerHandler();
  return axios.put(url.toString(), customerData, { headers: header });
};

export const deleteCustomer = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}`);
  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};

export const updateCustomerAddress = (payload) => {
  const { addressData, id, customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/addresses/${id}`);
  const header = headerHandler();
  return axios.put(url.toString(), addressData, { headers: header });
};

export const customersFiltersData = (payload) => {
  const url = new URL(`${API_ENDPOINT}/uihelper/customers`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

//Clients

export const getCustomerClientDetails = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/clients`);
  if (payload) {
    const {
      fromDate,
      toDate,
      order,
      businessTypes,
      states,
      cities,
      isActive,
      page,
    } = payload;
    if (page || page === 0) {
      url.searchParams.append("page", page + 1);
      url.searchParams.append("size", 10);
    }
    if (fromDate && toDate) {
      url.searchParams.append("startDt", format(fromDate, "yyyy-MM-dd"));
      url.searchParams.append("endDt", format(toDate, "yyyy-MM-dd"));
    }
    if (order) url.searchParams.append("order", order);
    if (businessTypes && businessTypes.length > 0)
      url.searchParams.append("businessTypes", businessTypes?.join(","));
    if (states && states.length)
      url.searchParams.append("states", states?.join(","));
    if (cities && cities.length > 0)
      url.searchParams.append("cities", cities?.join(","));
    if (isActive !== undefined && isActive !== "" && isActive !== null)
      url.searchParams.append("isActive", isActive);
  }

  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getOneClientDetails = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getOneClientLinks = (payload) => {
  const { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/links`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getOneClientAddresses = (payload) => {
  const { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/addresses`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const deleteClient = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}`
  );
  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};
// export const deleteClientAddress = (payload) => {
//   let { customerId, clientId, addressId } = payload;
//   let url = new URL(
//     `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/addresses/${addressId}`
//   );
//   const header = headerHandler();
//   return axios.delete(url.toString(), { headers: header });
// };

export const createClient = (payload) => {
  let { customerId, clientPayload } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/clients`);
  const header = headerHandler();
  return axios.post(url.toString(), clientPayload, { headers: header });
};

export const updateClient = (payload) => {
  let { clientPayload, clientId, customerId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), clientPayload, { headers: header });
};

export const getClientDetails = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};
export const getClientAddresss = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/addresses`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getClientLinks = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/links`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const createClientAddress = (payload) => {
  let { id, customerId, address } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${id}/addresses`
  );
  const header = headerHandler();
  return axios.post(
    url.toString(),
    { addresses: address },
    { headers: header }
  );
};

export const updateClientAddress = (payload) => {
  let { clientId, customerId, oldAddressId, registeredAddress } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/addresses/${oldAddressId}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), registeredAddress, { headers: header });
};

export const deleteClientAddress = (payload) => {
  let { clientId, customerId, oldClientAddressId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/addresses/${oldClientAddressId}`
  );
  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};

export const setCustomerStatus = (payload) => {
  const { isActive, customerId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/status?isActive=${isActive}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), {}, { headers: header });
};

export const setClientStatus = (payload) => {
  const { isActive, customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/status?isActive=${isActive}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), {}, { headers: header });
};

export const getUserProfileData = () => {
  let url = new URL(`${API_ENDPOINT}/users/profiles`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getCustomerUserRoles = (payload) => {
  let { customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/roles`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const createUser = (payload) => {
  let { customerId, userPayload } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/users`);
  const header = headerHandler();
  return axios.post(url.toString(), userPayload, { headers: header });
};

export const updateUser = (payload) => {
  let { customerId, userId, userPayload } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/users/${userId}`);
  const header = headerHandler();
  return axios.put(url.toString(), userPayload, { headers: header });
};

export const createAddress = (payload) => {
  let { id, customerId, addressPayload } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/users/${id}/addresses`
  );
  const header = headerHandler();
  return axios.post(url.toString(), addressPayload, { headers: header });
};

export const updateAddress = (payload) => {
  let { id, customerId, addressPayload, addressId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/users/${id}/addresses/${addressId}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), addressPayload, { headers: header });
};

export const getAllUsers = (payload) => {
  const { customerId, page } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/users`);
  if (page >= 0) {
    url.searchParams.append("page", page + 1);
    url.searchParams.append("size", 10);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getUserData = (payload) => {
  let { userId, customerId } = payload;
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/users/${userId}`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getClientUserRoles = (payload) => {
  let { customerId, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/roles`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getAllClientUsers = (payload) => {
  const { customerId, page } = payload;
  // console.log("customerIdFromAPI", customerId);
  let url = new URL(`${API_ENDPOINT}/customers/${customerId}/client-users`);
  if (page >= 0) {
    url.searchParams.append("page", page + 1);
    url.searchParams.append("size", 10);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const createClientUser = (payload) => {
  let { customerId, userPayload, clientId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users`
  );
  const header = headerHandler();
  return axios.post(url.toString(), userPayload, { headers: header });
};

export const createAddressForClientUser = (payload) => {
  let { id, clientId, customerId, addressPayload } = payload;

  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${id}/addresses`
  );
  const header = headerHandler();
  return axios.post(url.toString(), addressPayload, { headers: header });
};

export const updateClientUserData = (payload) => {
  let { clientId, customerId, userId, userPayload } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), userPayload, { headers: header });
};

export const updateClientUserAddress = (payload) => {
  let { clientId, userId, customerId, addressPayload, addressId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}/addresses/${addressId}`
  );
  const header = headerHandler();
  return axios.put(url.toString(), addressPayload, { headers: header });
};

export const getClientUserData = (payload) => {
  let { clientId, userId, customerId } = payload;
  let url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const setUserStatus = (payload) => {
  const { isActive, customerId, userId, clientId } = payload;
  let url = "";
  if (clientId) {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}/status`
    );
  } else {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/users/${userId}/status`
    );
  }

  const header = headerHandler();
  return axios.put(url.toString(), { isActive: isActive }, { headers: header });
};

export const deleteUser = (payload) => {
  const { clientId, userId, customerId } = payload;
  let url = "";
  if (clientId) {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}`
    );
  } else {
    url = new URL(`${API_ENDPOINT}/customers/${customerId}/users/${userId}`);
  }

  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};

export const getUserContext = (payload) => {
  const url = new URL(`${API_ENDPOINT}/users/context?configType=${payload}`);
  const headers = headerHandler();
  return axios.get(url.toString(), { headers });
};
/* export const login = (payload) => {
  const url = `${API_ENDPOINT}/auth/login`;
  console.log({ url: url });
  return API.post(url, payload);
}; */

export const fetchBlockchainData = (payload) => {
  const { inspectionId } = payload;

  let url = `${API_ENDPOINT}/inspections/${inspectionId}/verifyBcData`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getLocationData = (payload) => {
  const url = new URL(`${API_ENDPOINT_ORIGIN_SERVICE}/locations`);
  if (payload) {
    const { country, state, city, postalCode } = payload;
    // console.log(country, state, city, postalCode, url);
    if (postalCode && !state && !city) {
      url.searchParams.append("postalCode", postalCode);
    } else {
      if (country && state && city) {
        url.searchParams.append("country", country);
        url.searchParams.append("state", state);
        url.searchParams.append("city", city);
      } else if (country && state) {
        url.searchParams.append("country", country);
        url.searchParams.append("state", state);
      } else if (country) {
        url.searchParams.append("country", country);
      }
    }
    const header = headerHandler();
    return axios.get(url, { headers: header });
  }
};

export const getInspectionJobDetails = (payload) => {
  const { id } = payload;

  let url = `${API_ENDPOINT}/inspections/${id}/jobs`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const createClientLocations = async (payload) => {
  // const { id } = payload;
  const header = headerHandler();
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins`;

  let promiseArray = payload.map((location) => {
    return axios.post(url, location, { headers: header });
  });

  const res = await Promise.all(promiseArray);
  return res;
};

export const createLocation = (payload) => {
  const { locationPayload } = payload;
  const header = headerHandler();
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins`;
  return axios.post(url, locationPayload, { headers: header });
};

export const getOriginLocation = (payload) => {
  const { originId } = payload;
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins/${originId}`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deleteLocation = (payload) => {
  const { idToDelete } = payload;

  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins/${idToDelete}`;
  const header = headerHandler();
  return axios.delete(url, { headers: header });
};

export const updateLocation = (payload) => {
  const { locationPayload, originId } = payload;
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins/${originId}`;
  const header = headerHandler();
  return axios.put(url, locationPayload, { headers: header });
};

export const updateLocationStatus = (payload) => {
  const { originId, managerId, status } = payload;
  let url = `${API_ENDPOINT_ORIGIN_SERVICE}/origins/${originId}/status?managerId=${managerId}`;
  const header = headerHandler();
  return axios.put(url, { status }, { headers: header });
};

//Will give array of report detail object.
export const getInspectionReport = (payload) => {
  const { inspectionId } = payload;
  let url = `${API_ENDPOINT}/inspections/${inspectionId}/reports`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deleteUserAddress = (payload) => {
  const { customerId, clientId, userId, oldAddressId } = payload;
  let url = "";
  if (clientId) {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/${userId}/addresses/${oldAddressId}`
    );
  } else {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/users/${userId}/addresses/${oldAddressId}`
    );
  }

  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};

// delete Additionally Address of Agency/customer

export const deleteAdditionalAddress = (payload) => {
  const { customerId, oldAddressId } = payload;
  const url = new URL(
    `${API_ENDPOINT}/customers/${customerId}/addresses/${oldAddressId}`
  );

  const header = headerHandler();
  return axios.delete(url.toString(), { headers: header });
};

//Agency Dashboard /v2 APIs

export const getInspectionsCompletedData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/inspections-insights`);
  if (payload) {
    const {
      start_dt,
      end_dt,
      inspection_types,
      auditors,
      clientIds,
      unit,
      status,
    } = payload;
    if (start_dt && end_dt) {
      url.searchParams.append("start_dt", start_dt);
      url.searchParams.append("end_dt", end_dt);
    }
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
    if (unit) url.searchParams.append("type", unit);
    if (status) url.searchParams.append("status", status);
  }

  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getSitesInspectedData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/origin-insights`);
  if (payload) {
    // start_dt,
    // end_dt,
    // inspection_types,
    // auditors,
    // clientIds,
    // unit,
    const {
      start_dt,
      end_dt,
      inspection_types,
      auditors,
      clientIds,
      unit,
    } = payload;
    if (start_dt && end_dt) {
      url.searchParams.append("start_dt", start_dt);
      url.searchParams.append("end_dt", end_dt);
    }
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
    if (unit) url.searchParams.append("type", unit);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};
export const getClientsInspectedData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/client-inspected`);
  if (payload) {
    // start_dt,
    // end_dt,
    // inspection_types,
    // auditors,
    // clientIds,
    // unit,
    const {
      start_dt,
      end_dt,
      inspection_types,
      auditors,
      clientIds,
      unit,
    } = payload;
    if (start_dt && end_dt) {
      url.searchParams.append("start_dt", start_dt);
      url.searchParams.append("end_dt", end_dt);
    }
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
    if (unit) url.searchParams.append("type", unit);
  }

  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const getAgencyAnalyticsData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/insights`);
  if (payload) {
    const { inspection_types, auditors, clientIds } = payload;
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
  }
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};
export const getAgencyGeoReachData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/geo-insights`);

  if (payload) {
    const { start_dt, end_dt, inspection_types, auditors, clientIds } = payload;
    if (start_dt && end_dt) {
      url.searchParams.append("start_dt", start_dt);
      url.searchParams.append("end_dt", end_dt);
    }
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
  }

  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

// This will provide all the data on client statistics tab for Agency dashboard
export const fetchClientsStatisticsData = (payload) => {
  let url = new URL(`${API_ENDPOINT_V2}/analytics/client-inspections`);
  if (payload) {
    // start_dt,
    // end_dt,
    // inspection_types,
    // auditors,
    // clientIds,
    // unit,
    const {
      start_dt,
      end_dt,
      inspection_types,
      auditors,
      clientIds,
      unit,
    } = payload;
    if (start_dt && end_dt) {
      url.searchParams.append("start_dt", start_dt);
      url.searchParams.append("end_dt", end_dt);
    }
    if (inspection_types) {
      url.searchParams.append("categoryName", "inspection");
      url.searchParams.append("categoryTypes", inspection_types);
    }
    if (auditors) url.searchParams.append("auditors", auditors);
    if (clientIds) url.searchParams.append("clients", clientIds);
    if (unit) url.searchParams.append("type", unit);
  }

  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

// get auditor track location information

export const getAuditorTrackLocation = (payload) => {
  const { jobId, taskId, inspectionId } = payload;
  const url = new URL(
    `${API_ENDPOINT}/inspections/${inspectionId}/jobs/${jobId}/tasks/${taskId}/location-tracks`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const bulkUserCreate = (payload) => {
  const { customerId, clientId, file } = payload;
  let url;
  if (clientId) {
    url = new URL(
      `${API_ENDPOINT}/customers/${customerId}/clients/${clientId}/users/bulk-upload`
      // /customers/:customerId/clients/:clientId/users/bulk-upload
    );
  } else {
    url = new URL(`${API_ENDPOINT}/customers/${customerId}/users/bulk-upload`);
  }
  const formData = new FormData();
  formData.append("file", file);

  const header = headerHandler();
  return axios.post(url.toString(), formData, {
    headers: { ...header, "Content-Type": "multipart/form-data" },
  });
};

export const bulkOriginCreate = (payload) => {
  const { file, managerId } = payload;
  let url;
  url = new URL(
    `${API_ENDPOINT_ORIGIN_SERVICE}/origins/bulk-upload?managerId=${managerId}`
  );
  const formData = new FormData();
  formData.append("file", file);
  const header = headerHandler();
  return axios.post(url.toString(), formData, {
    headers: { ...header, "Content-Type": "multipart/form-data" },
  });
};
// check is hedera is enable for inspection or not

export const isHederaEnabled = (payload) => {
  const { inspectionId } = payload;
  const url = new URL(
    `${API_ENDPOINT}/inspections/${inspectionId}/hederaVerifiable`
  );
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

// inspection filter data
export const inspectionTableFiltersData = (payload) => {
  const url = new URL(`${API_ENDPOINT}/uihelper/inspections`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

// Pending Approvals filter data
export const pendingApprovalsFiltersData = (payload) => {
  const url = new URL(`${API_ENDPOINT}/uihelper/pendingapprovals`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

export const clientFiltersData = (payload) => {
  const url = new URL(`${API_ENDPOINT}/uihelper/clients`);
  const header = headerHandler();
  return axios.get(url.toString(), { headers: header });
};

//get all the image urls in single call
export const getImageUrls = (payload) => {
  const { fileNames } = payload;
  const url = new URL(`${API_ENDPOINT}/extras/pre-signed-urls`);
  const header = headerHandler();
  return axios.post(url.toString(), { fileNames }, { headers: header });
};