import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state?.jobs || initialState;

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehouseDetails
);
export const selectWarehouseDataByClientId = createSelector(
  [selectDomain],
  (state) => state.warehouseDetailsByClientId
);
export const selectWorkflowList = createSelector(
  [selectDomain],
  (state) => state.workflowList
);
export const selectWorkflowData = createSelector(
  [selectDomain],
  (state) => state.workflowData
);
export const selectJob = createSelector([selectDomain], (state) => state.job);
export const selectUserDetails = createSelector(
  [selectDomain],
  (state) => state.userDetails
);
export const selectLoadingUserDetails = createSelector(
  [selectDomain],
  (state) => state.loadingUserDetails
);
export const selectWarehouseDetail = createSelector(
  [selectDomain],
  (state) => state.warehouseDetail
);
export const selectTasksData = createSelector(
  [selectDomain],
  (state) => state.tasks
);

export const selectInspection = createSelector(
  [selectDomain],
  (state) => state.inspection
);
export const selectUserDetail = createSelector(
  [selectDomain],
  (state) => state.userDetail
);
export const selectuserProfileImgUrls = createSelector(
  [selectDomain],
  (state) => state.userProfileImgUrls
);
export const selectGetAllJobs = createSelector(
  [selectDomain],
  (state) => state.allJobs
);

export const selectGetAllTasks = createSelector(
  [selectDomain],
  (state) => state.allTasks
);
export const selectUserDetailsArr = createSelector(
  [selectDomain],
  (state) => state.userDetailsArr
);
export const selectMultipleAvatarUrls = createSelector(
  [selectDomain],
  (state) => state.userAvatarUrlsArr
);
export const selectCustomerUsers = createSelector(
  [selectDomain],
  (state) => state.customerUsers
);
export const selectClientsOfCustomer = createSelector(
  [selectDomain],
  (state) => state.clientsOfCustomer
);
export const selectWorkflow = createSelector(
  [selectDomain],
  (state) => state.workflow
);

export const selectSectionsForBlocks = createSelector(
  [selectDomain],
  (state) => state.sections || []
);

export const selectBlocksForComposites = createSelector(
  [selectDomain],
  (state) => state.blocks || []
);

export const selectCompositesForComponents = createSelector(
  [selectDomain],
  (state) => state.composites || []
);

export const selectComponentsForElements = createSelector(
  [selectDomain],
  (state) => state.components || []
);

export const selectReviewData = createSelector(
  [selectDomain],
  (state) => state.reviewData || []
);
export const selectInspectionDetails = createSelector(
  [selectDomain],
  (state) => state.inspectionDetails || []
);
export const selectFormTaskDetails = createSelector(
  [selectDomain],
  (state) => state.formTaskDetails || []
);

export const selectAvatarUrl = createSelector(
  [selectDomain],
  (state) => state.avatarUrl || null
);

export const selectJobCreationStatus = createSelector(
  [selectDomain],
  (state) => state.creatingJob || null
);

export const selectJobUpdationStatus = createSelector(
  [selectDomain],
  (state) => state.updatingJob || null
);
export const jobLoadingStatus = createSelector(
  [selectDomain],
  (state) => state.jobLoading
);
export const inspectionReportDetails = createSelector(
  [selectDomain],
  (state) => state.inspectionReportDetails
);

export const selectJobCount = createSelector(
  [selectDomain],
  (state) => state.jobCount
);

export const selectTrackLocation = createSelector(
  [selectDomain],
  (state) => state.trackLocation
);
export const selectLocationLoading = createSelector(
  [selectDomain],
  (state) => state.locationLoading
);
export const selectImageUrls = createSelector(
  [selectDomain],
  (state) => state.imageUrls
);
