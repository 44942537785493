import React, { useEffect, useState } from "react";
import { HStack, Box, Text, ButtonGroup } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import { useDispatch } from "react-redux";
import Delete from "../../../Components/Delete";
import Edit from "../../../Components/Edit";
import NavigateTo from "../../../Components/NavigateTo";
import { actions } from "../../Create/slice";

export default function Option({
  element,
  label,
  value,
  componentId,
  sectionId,
  navigationDisabled,
}) {
  const elementId = element.id;
  const defaultElement = element.default;

  const [titleOption, setTitleOption] = useState(() => {
    if (
      element.properties &&
      element.properties.navigations !== undefined &&
      Array.isArray(element.properties.navigations) &&
      element.properties.navigations.length > 0
    ) {
      let navigationCopy = _.cloneDeep(element.properties.navigations);
      const navigation = navigationCopy
        .reverse()
        .find((navigation) => navigation.value === value);
      if (navigation) {
        return { label: navigation.value, value: navigation.sectionId };
      }
    }
    return { label: "", value: "" };
  });

  const dispatch = useDispatch();

  const handleSubmit = (section) => {
    setTitleOption(section);
    handleEditNavigation(section);
  };

  const handleSetDefault = () => {
    dispatch(
      actions.editDefault({ elementId, componentId, option: { value, label } })
    );
  };

  const handleDeleteOption = () => {
    dispatch(actions.deleteOption({ elementId, value, componentId }));
  };

  const handleEditNavigation = (sectionId) => {
    dispatch(
      actions.editNavigation({ elementId, componentId, sectionId, value })
    );
  };

  return (
    <HStack gap="32px" style={{ padding: "8px 0px" }}>
      <Box w="30%" style={{ paddingLeft: "16px" }}>
        <Text color="login.darkText">{value}</Text>
      </Box>
      <Box w="30%" style={{ paddingLeft: "16px" }}>
        <Text color="login.darkText">{label}</Text>
      </Box>
      <ButtonGroup
        justifyContent="center"
        size="md"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <Edit duplicateHandler={() => {}} />
        <Delete deleteHandler={() => {}} /> */}
        <Button
          label={
            defaultElement &&
            defaultElement.value &&
            defaultElement.value === value
              ? "Default"
              : "Set as default"
          }
          variant="ghost"
          color="login.darkText"
          size="sm"
          onClick={handleSetDefault}
        />
        <Delete deleteHandler={handleDeleteOption} />
      </ButtonGroup>
      {element.htmlType !== "checkbox" &&
        element.htmlType !== "checkboxSearch" &&
        !navigationDisabled && (
          <NavigateTo
            title={titleOption}
            setTitle={setTitleOption}
            handleSubmit={handleSubmit}
            parentId={sectionId}
          />
        )}
    </HStack>
  );
}
