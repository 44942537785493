import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Badge,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import {
  convertArrayToObject,
  isModified,
  mergeModifiedStatus,
  transformObjectKeys,
} from "./HederaHelperFunctions";

const ComponentRepeatHedera = ({
  component,
  data,
  diffElementMap,
  count,
  bgColor,
  isHederaData,
  blobNames,
  tabIndex,
  elHeightsLeft,
  elHeightsRight,
}) => {
  const [displayData, setDisplayData] = useState([]);

  const compTitle = component.title;
  const elements = component.structure?.elements;
  ``;

  useEffect(() => {
    if (
      elements &&
      elements.length &&
      data &&
      data.length &&
      diffElementMap &&
      diffElementMap.length &&
      !displayData.length
    ) {
      let labelVal = [];
      elements.forEach((el) => {
        const id = el.id;
        let dataArr;
        if (isHederaData) {
          dataArr = transformObjectKeys(data);
        } else {
          dataArr = data;
        }
        const hederaData = convertArrayToObject(diffElementMap, 1);
        const hederaDataLocal = convertArrayToObject(diffElementMap, 0);
        if (isHederaData) {
          dataArr.map((obj) => {
            const key = Object.keys(obj)[0];
            if (hederaData.hasOwnProperty(obj[key].id)) {
              obj[key] = hederaData[obj[key].id];
            }
          });
        }
        const elementData = dataArr.find((obj) => obj[id]);
        if (elementData) {
          Object.values(elementData).forEach((obj) => {
            const isHederaDiffLocal = hederaDataLocal.hasOwnProperty(obj.id);
            const isHederaDiff = hederaData.hasOwnProperty(obj.id);

            let modifiedStatus;
            if (
              hederaData.hasOwnProperty(obj.id) &&
              obj.itemType !== "multiFileUpload" &&
              obj.itemType !== "singleFileUpload" &&
              obj.itemType !== "fileUpload" &&
              obj.itemType !== "checkbox" &&
              obj.itemType !== "checkboxWithSearch" &&
              obj.itemType !== "dropdownWithMultiSelect"
            ) {
              modifiedStatus = hederaData[obj.id].attachments;
            } else if (
              hederaData.hasOwnProperty(obj.id) &&
              (obj.itemType === "checkbox" ||
                obj.itemType === "checkboxWithSearch" ||
                obj.itemType === "dropdownWithMultiSelect")
            ) {
              modifiedStatus = hederaData[obj.id].attachments.map((item) =>
                isModified(item)
              );
            } else if (
              obj.itemType === "multiFileUpload" ||
              obj.itemType === "singleFileUpload" ||
              obj.itemType === "fileUpload"
            ) {
              if (isHederaDiffLocal || isHederaDiff) {
                const mergeModifiedStatus = (
                  hederaDataLocalArray,
                  hederaDataArray
                ) => {
                  const result = [];

                  // Determine the length of the arrays
                  const maxLength = Math.max(
                    hederaDataLocalArray.length,
                    hederaDataArray.length
                  );
                  const minLength = Math.min(
                    hederaDataLocalArray.length,
                    hederaDataArray.length
                  );

                  // Compare common indices
                  for (let i = 0; i < minLength; i++) {
                    const localItem = hederaDataLocalArray[i];
                    const dataItem = hederaDataArray[i];

                    // If either localItem or dataItem has modified as true, pick modified: true
                    result.push({
                      modified:
                        localItem.modified === true ||
                        dataItem.modified === true
                          ? true
                          : false,
                    });
                  }

                  // Handle extra elements in the longer array (if any)
                  if (hederaDataLocalArray.length > minLength) {
                    result.push(...hederaDataLocalArray.slice(minLength));
                  } else if (hederaDataArray.length > minLength) {
                    result.push(...hederaDataArray.slice(minLength));
                  }

                  return result;
                };

                // Call the mergeModifiedStatus function
                const modifiedStatusLocal = isHederaDiffLocal
                  ? hederaDataLocal[obj.id]?.itemInfo?.hashArray?.map((item) =>
                      isModified(item)
                    )
                  : [];
                const modifiedStatusRemote = isHederaDiff
                  ? hederaData[obj.id]?.itemInfo?.hashArray?.map((item) =>
                      isModified(item)
                    )
                  : [];

                modifiedStatus = mergeModifiedStatus(
                  modifiedStatusLocal,
                  modifiedStatusRemote
                );
              } else {
                const attachLen = obj.attachments.length;
                let arrayOfObjects = [];
                for (let i = 1; i <= attachLen; i++) {
                  arrayOfObjects.push({
                    modified: false,
                  });
                }
                modifiedStatus = arrayOfObjects;
              }
            } else {
              const attachLen = obj.attachments.length;
              let arrayOfObjects = [];
              for (let i = 1; i <= attachLen; i++) {
                arrayOfObjects.push({
                  modified: false,
                });
              }
              modifiedStatus = arrayOfObjects;
            }

            if (
              (obj.itemType === "multiFileUpload" ||
                obj.itemType == "singleFileUpload" ||
                obj.itemType == "fileUpload") &&
              hederaDataLocal.hasOwnProperty(obj.id)
            ) {
              const fileAttachment = isHederaData
                ? hederaData[obj.id]?.itemInfo?.hashArray
                : hederaDataLocal[obj.id]?.itemInfo?.hashArray;
              fileAttachment?.forEach((val, index) => {
                if (!labelVal[index]) {
                  labelVal[index] = [];
                }
                labelVal[index].push({
                  id: `${obj.id}_${index}`,
                  title: obj.title,
                  value:
                    typeof val === "object" && !Array.isArray(val)
                      ? val.value
                      : val,
                  itemType: obj.itemType,
                  modified: modifiedStatus[index].modified,
                });
              });
            } else {
              obj.attachments?.forEach((val, index) => {
                if (!labelVal[index]) {
                  labelVal[index] = [];
                }
                labelVal[index].push({
                  id: `${obj.id}_${index}`,
                  title: obj.title,
                  value:
                    typeof val === "object" && !Array.isArray(val)
                      ? val.value
                      : val,
                  itemType: obj.itemType,
                  modified: modifiedStatus[index].modified,
                });
              });
            }
          });
        }
      });
      setDisplayData(labelVal);
    }
  }, [elements, isHederaData, count, diffElementMap]);

  useEffect(() => {
    // Update heights of left and right elements
    const updateElementHeights = () => {
      Object.keys(elHeightsLeft.current).forEach((elementId) => {
        const leftHeight = elHeightsLeft.current[elementId];
        const rightHeight = elHeightsRight.current[elementId];
        const maxHeight = Math.max(leftHeight, rightHeight);

        if (leftHeight < maxHeight) {
          elHeightsLeft.current[elementId] = maxHeight;
        }
        if (rightHeight < maxHeight) {
          elHeightsRight.current[elementId] = maxHeight;
        }
      });
    };

    updateElementHeights();
  }, [elHeightsLeft.current, elHeightsRight.current]);
  return (
    <>
      {displayData && displayData.length ? (
        <Flex
          gap="10px"
          w="100%"
          padding="24px 16px"
          borderBottom="1px solid var(--Grey-Gray-200, #E2E8F0)"
        >
          <Flex
            border="1px solid #545454"
            borderRadius="50%"
            h="16px"
            w="16px"
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <Text fontSize="8px" color="#545454">
              {count}
            </Text>
          </Flex>

          <Box w={"100%"}>
            <VStack gap="12px">
              <Text
                width="100%"
                fontSize="16px"
                fontWeight="500"
                color="#2D3748"
              >
                {compTitle}
              </Text>
              {displayData.length ? (
                displayData.map((elArray, compIndex) => {
                  const hasValidData = elArray.some((currEl) => {
                    const currElModified = currEl.modified;
                    return !(
                      (tabIndex === 2 && currElModified) ||
                      (tabIndex === 1 && currElModified === false)
                    );
                  });

                  if (!hasValidData) return null;
                  return (
                    <VStack
                      key={compIndex}
                      gap="16px"
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="8px"
                      overflow="hidden"
                      w="100%"
                    >
                      <Table size="sm" fontSize={"12px"}>
                        <Tbody w="100%">
                          {elArray.map((currEl, elIndex) => {
                            const currElModified = currEl.modified;
                            const rowBackgroundColor = currElModified
                              ? bgColor
                              : "white";
                            if (tabIndex === 2 && currElModified) {
                              return <></>;
                            } else if (
                              tabIndex === 1 &&
                              currElModified === false
                            ) {
                              return <></>;
                            }
                            return (
                              <Tr
                                key={`${compIndex}-${elIndex}`}
                                bg={rowBackgroundColor}
                                w="100%"
                                ref={(el) => {
                                  if (el) {
                                    if (isHederaData) {
                                      elHeightsRight.current[`${currEl.id}`] =
                                        el.clientHeight;
                                    } else {
                                      elHeightsLeft.current[`${currEl.id}`] =
                                        el.clientHeight;
                                    }
                                  }
                                }}
                                height={
                                  elHeightsRight.current[currEl.id] >
                                  elHeightsLeft.current[currEl.id]
                                    ? `${elHeightsRight.current[currEl.id]}px`
                                    : `${elHeightsLeft.current[currEl.id]}px`
                                }
                                fontSize="12px"
                              >
                                <Td
                                  fontWeight="500"
                                  borderRight="1px solid"
                                  borderColor="gray.300"
                                  width="50%"
                                >
                                  <Text>
                                    {capitalizeStringUpdated(currEl?.title)}
                                  </Text>
                                </Td>
                                <Td
                                  width="50%"
                                  borderColor="gray.300"
                                  color="#4A5568"
                                >
                                  {currEl.itemType === "multiFileUpload" ||
                                  currEl.itemType === "singleFileUpload" ||
                                  currEl.itemType === "fileUpload" ? (
                                    !currElModified ? (
                                      currEl.value.map((info, index) => (
                                        <Box key={index} w="40%">
                                          <Image
                                            w="100%"
                                            h="94px"
                                            src={blobNames[info.imageName]}
                                            borderRadius="8px"
                                            border="1px solid #cbd5e0"
                                          />
                                        </Box>
                                      ))
                                    ) : (
                                      <Flex flexWrap="wrap" gap="10px" w="100%">
                                        {currEl.value.map((info) => {
                                          let imageName = info.name;
                                          let modified = info.modified;
                                          let hash = info.hash;
                                          return isHederaData &&
                                            modified === "Deleted" ? (
                                            <Tooltip
                                              hasArrow
                                              isDisabled={modified === false}
                                              label={
                                                modified !== false
                                                  ? hash || ""
                                                  : ""
                                              }
                                            >
                                              <VStack w="40%">
                                                <Box
                                                  p="10px"
                                                  borderRadius="8px"
                                                  h="94px"
                                                  w="100%"
                                                  color="var(--gray-600, #4A5568)"
                                                  background="var(--Grey-Gray-200, #E2E8F0)"
                                                  fontSize="7px"
                                                  overflow="hidden"
                                                  textOverflow="ellipsis"
                                                  whiteSpace="normal"
                                                >
                                                  {hash || ""}
                                                </Box>
                                              </VStack>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              hasArrow
                                              isDisabled={modified === false}
                                              label={
                                                modified !== false
                                                  ? `Image is ${modified?.toLowerCase()} from the platform`
                                                  : ""
                                              }
                                            >
                                              <VStack w="40%">
                                                <Image
                                                  key={imageName}
                                                  h="94px"
                                                  w="100%"
                                                  src={blobNames[imageName]}
                                                  borderRadius="8px"
                                                  border="1px solid #cbd5e0"
                                                />
                                                {modified !== false && (
                                                  <Badge
                                                    colorScheme={
                                                      modified === "Deleted"
                                                        ? "red"
                                                        : "blue"
                                                    }
                                                  >
                                                    {modified?.toUpperCase()}
                                                  </Badge>
                                                )}
                                              </VStack>
                                            </Tooltip>
                                          );
                                        })}
                                      </Flex>
                                    )
                                  ) : currEl.itemType === "datepicker" ? (
                                    // New itemType check for "textInput"
                                    currEl.value.slice(0, 10)
                                  ) : Array.isArray(currEl.value) ? (
                                    currEl.value
                                      .map((val) =>
                                        typeof val === "object" &&
                                        !Array.isArray(val)
                                          ? val.value
                                          : val
                                      )
                                      .join(", ")
                                  ) : (
                                    currEl.value
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </VStack>
                  );
                })
              ) : (
                <></>
              )}
            </VStack>
          </Box>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default ComponentRepeatHedera;
