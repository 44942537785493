import * as api from "../../services/apis";
import { downloadImage } from "utils/commonFunctions";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { isArray } from "lodash";

export function* handleFetchFiltersData(action) {
  try {
    const response = yield call(api.clientFiltersData, action.payload);
    const { data } = response;
    yield put(actions.fetchFiltersDataSuccess(data?.payload));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchFiltersDataFailure(e));
  }
}
export function* handlefetchClientsOfCustomer(action) {
  try {
    const response = yield call(api.getCustomerClientDetails, action.payload);
    const { data } = response;
    yield put(actions.fetchClientsOfCustomerSuccess(data));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchClientsOfCustomerFaliure(e));
  }
}
// function* handlePostClientData() {
// export function* handlefetchClientsOfCustomer(action) {
//   try {
//     const response = yield call(api.getCustomerClientDetails, action.payload);
//     const { data } = response;
//     yield put(actions.fetchAllCustomersSuccess(data?.payload));
//   } catch (e) {
//     console.log(e);
//     yield put(actions.fetchAllCustomersFailure(e));
//   }
// }
function* handlePostClientData(action) {
  try {
    const response = yield call(api.createClient, action.payload);
    action.payload.onSuccess(response.data.payload.clientId);
  } catch (error) {
    yield put(actions.postClientDataFaliure(error));
    action.payload.onFailure();
  }
}
export function* handlefetchOneClientDetails(action) {
  try {
    const response = yield call(api.getOneClientDetails, action.payload);
    const { data } = response;
    yield put(actions.fetchOneClientDetailsSuccess(data?.payload));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchOneClientDetailsFailure(e));
  }
}
export function* handlefetchOneClientLinks(action) {
  try {
    const response = yield call(api.getOneClientLinks, action.payload);
    const { data } = response;
    yield put(
      actions.fetchOneClientLinksSuccess({
        clientId: action.payload.clientId,
        response: data.payload,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(actions.fetchOneClientLinksFailure(e));
  }
}
export function* handlefetchOneClientAddresses(action) {
  try {
    const response = yield call(api.getOneClientAddresses, action.payload);
    const { data } = response;
    yield put(
      actions.fetchOneClientAddressesSuccess({
        clientId: action.payload.clientId,
        response: data.payload,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(actions.fetchOneClientAddressesFailure(e));
  }
}
export function* handleDeleteClient(action) {
  try {
    const response = yield call(api.deleteClient, action.payload);
    const { data } = response;
    yield put(actions.deleteClientSuccess(data?.payload));
    action.payload.onSuccess(data.success);
  } catch (e) {
    console.log(e);
    yield put(actions.deleteClientFailure(e));
    action.payload.onSuccess(e.response.data.success);
  }
}
// export function* handleDeleteClientAddress(action) {
//   try {
//     const response = yield call(api.deleteClientAddress, action.payload);
//     const { data } = response;
//     yield put(actions.deleteClientAddressSuccess(data?.payload));
//   } catch (e) {
//     console.log(e);
//     yield put(actions.deleteClientAddressFailure(e));
//   }
// }
function* handleFetchProfileImgURLArr(action) {
  try {
    const response = yield call(api.getUserProfileImg, action.payload);
    yield put(actions.fetchProfileImgURLArrSuccess(response));
  } catch (e) {
    yield put(actions.fetchProfileImgURLArrFailure(e));
  }
}
// export function* ClientSaga() {
//   console.log(response);
// }

function* handleUpdateClientData(action) {
  try {
    const response = yield call(api.updateClient, action.payload);
    yield put(actions.updateClientDataSuccess(response.data));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.updateClientDataFailure(error));
  }
}

function* handleUpdateClientAddress(action) {
  try {
    const response = yield call(api.updateClientAddress, action.payload);
    yield put(actions.updateClientAddressSuccess(response.data));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.updateClientAddressFailure(error));
  }
}

function* handleFetchClientData(action) {
  try {
    const response = yield call(api.getClientDetails, action.payload);
    yield put(actions.fetchClientDataSuccess(response.data.payload));
  } catch (error) {
    yield put(actions.fetchClientDataFaliure(error));
  }
}

function* handleFetchClientAddress(action) {
  try {
    const response = yield call(api.getClientAddresss, action.payload);
    yield put(actions.fetchClientAddressSuccess(response.data.payload));
  } catch (error) {
    yield put(actions.fetchClientaddressFaliure(error));
  }
}
function* handleFetchClientLinks(action) {
  try {
    const response = yield call(api.getClientLinks, action.payload);
    yield put(actions.fetchClientLinksSuccess(response.data.payload));
  } catch (error) {
    yield put(actions.fetchClientLinksFaliure(error));
  }
}
function* handlePostAddressData(action) {
  try {
    const response = yield call(api.createClientAddress, action.payload);
    yield put(actions.postClientAddressSuccess(response));
    action.payload.onSuccess(response.data.success);
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.postClientAddressFaliure(error));
  }
}

export function* handleFetchAvatarURL({ payload }) {
  try {
    const res = yield call(api.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getAvatarURLSuccess(data?.payload));
  } catch (err) {
    yield put(actions.getAvatarURLFailure(err));
  }
}

export function* handleDeleteClientAddress({ payload }) {
  try {
    const res = yield call(api.deleteClientAddress, payload);
    payload.onSuccess(res.data.success);
    const { data } = res;
    yield put(actions.deleteClientAddressSuccess(data?.payload));
  } catch (err) {
    payload.onSuccess();
    yield put(actions.deleteClientAddressFailure(err));
  }
}

function* handleClientStatus(action) {
  try {
    const response = yield call(api.setClientStatus, action.payload);
    yield put(actions.fetchChangeClientStatusSuccess());
  } catch (error) {
    yield put(actions.fetchChangeClientStatusFailure());
  }
}
function* handleClearClientAddress(action) {
  try {
    yield put(actions.clearClientAddressSuccess());
  } catch (error) {
    yield put(actions.clearClientAddressFailure());
  }
}

function* handleFetchClearStates({ payload }) {
  yield put(actions.clearStateSuccess());
}
function* handleFetchClearAllStates({ payload }) {
  yield put(actions.clearAllStateSuccess());
}
// function* handleFetchClientsUsers(action) {
//   try {
//     const response = yield call(api.getAllClientUsers, action.payload);
//     const { data } = response;
//     yield put(actions.fetchClientUserSuccess(data.payload));
//   } catch (error) {
//     yield put(actions.fetchClientUserFailure(error));
//   }
// }

// function* handleFetchClientUserRoles(action) {
//   try {
//     const response = yield call(api.getClientUserRoles, action.payload);
//     const { data } = response;
//     yield put(actions.fetchAllClientUserRolesSuccess(data?.payload));
//   } catch (error) {
//     yield put(actions.fetchAllClientUserRolesFailure(error));
//   }
// }
// function* handleCreateClientUser(action) {
//   try {
//     const response = yield call(api.createClientUser, action.payload);
//     console.log(response);
//     action.payload.onSuccess(response.data.payload.userId);

//     yield put(actions.createClientUserSuccess());
//   } catch (error) {
//     console.log(error);
//     action.payload.onFailure(error.response.data.reason);
//     yield put(actions.createClientUserFailure(error));
//   }
// }

function* handleFetchLocationData(action) {
  try {
    const response = yield call(api.getLocationData, action.payload);
    if (isArray(response.data.payload)) {
      action.payload.onSuccess();
      // return
    } else {
      yield put(
        actions.fetchLocationDataSuccess({
          response: response.data.payload,
          id: action.payload.id,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.fetchLocationDataFailure(error));
  }
}
function* handlePostMultipleClientLocation(action) {
  try {
    const response = yield call(api.createClientLocations, action.payload);
    console.log(response);
    yield put(actions.fetchPostMultipleClientLocationSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.fetchPostMultipleCLientLocationFailure(error));
  }
}

function* handleCreateClientLocation(action) {
  try {
    const response = yield call(api.createLocation, action.payload);
    action.payload.onSuccess();
    yield put(actions.createClientLocationSuccess());
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.createClientLocationFailure(error));
  }
}

function* handleUpdateLocationStatus(action) {
  try {
    const response = yield call(api.updateLocationStatus, action.payload);
    action.payload.onSuccess();
    yield put(actions.createClientLocationSuccess());
  } catch (error) {
    action.payload.onFailure();
    yield put(actions.createClientLocationFailure(error));
  }
}

function* fetchWarehouseDetails(action) {
  try {
    const response = yield call(api.getWarehouseDetails, action.payload);
    const { data } = response;
    yield put(actions.fetchWarehouseDetailsSuccess(data.payload));
  } catch (e) {
    yield put(actions.fetchWarehouseDetailsFailure(e));
  }
}

function* handleDeleteLocation(action) {
  try {
    const response = yield call(api.deleteLocation, action.payload);
    const { data } = response;
    action.payload.onSuccess();

    yield put(actions.deleteLocationSuccess());
  } catch (e) {
    action.payload.onFailure();

    yield put(actions.deleteLocationFailure());
  }
}

function* handleUpdateLocation(action) {
  try {
    const response = yield call(api.updateLocation, action.payload);
    const { data } = response;
    action.payload.onSuccess();
    yield put(actions.deleteLocationSuccess());
  } catch (e) {
    action.payload.onFailure();
    yield put(actions.deleteLocationFailure());
  }
}


export function* watchClientSaga() {
  //to get filters data
  yield takeLatest(actions.fetchFiltersData.type, handleFetchFiltersData);

  //get all clients of customer
  yield takeLatest(
    actions.fetchClientsOfCustomer.type,
    handlefetchClientsOfCustomer
  );
  // yield takeLatest(actions.postClientDataRequest, handlePostClientData);
  yield takeLatest(actions.fetchOneClientDetails, handlefetchOneClientDetails);
  yield takeEvery(actions.fetchOneClientLinks, handlefetchOneClientLinks);
  yield takeEvery(
    actions.fetchOneClientAddresses,
    handlefetchOneClientAddresses
  );
  yield takeLatest(actions.fetchOneClientDetails, handlefetchOneClientDetails);
  yield takeLatest(actions.deleteClient, handleDeleteClient);
  // yield takeLatest(actions.deleteClientAddress, handleDeleteClientAddress);
  yield takeLatest(
    actions.fetchProfileImgURLArr.type,
    handleFetchProfileImgURLArr
  );
  yield takeEvery(actions.postClientDataRequest, handlePostClientData);
  yield takeLatest(actions.postClientAddressRequest, handlePostAddressData);
  yield takeLatest(actions.fetchClientDataRequest, handleFetchClientData);
  yield takeLatest(actions.fetchClientAddressRequest, handleFetchClientAddress);
  yield takeLatest(actions.fetchAvatarURL.type, handleFetchAvatarURL);
  yield takeLatest(actions.fetchClientLinksRequest, handleFetchClientLinks);
  yield takeLatest(actions.updateClientData, handleUpdateClientData);
  yield takeLatest(actions.updateClientAddress, handleUpdateClientAddress);
  yield takeLatest(actions.deleteClientAddress, handleDeleteClientAddress);
  yield takeEvery(actions.fetchChangeClientStatus.type, handleClientStatus);
  yield takeLatest(actions.clearClientAddress.type, handleClearClientAddress);
  yield takeLatest(actions.clearStates.type, handleFetchClearStates);
  yield takeLatest(actions.clearAllStates.type, handleFetchClearAllStates);

  yield takeEvery(actions.fetchLocationData.type, handleFetchLocationData);
  // yield takeLatest(actions.fetchClientUser.type, handleFetchClientsUsers);
  // yield takeLatest(
  //   actions.fetchAllClientUserRoles.type,
  //   handleFetchClientUserRoles
  // );
  // yield takeLatest(actions.createClientUser.type, handleCreateClientUser);
  yield takeLatest(
    actions.fetchPostMultipleClientLocation.type,
    handlePostMultipleClientLocation
  );
  yield takeEvery(
    actions.fetchWarehouseDetailsRequest.type,
    fetchWarehouseDetails
  );
  yield takeLatest(actions.deleteLocation.type, handleDeleteLocation);
  yield takeLatest(actions.updateClientLocation.type, handleUpdateLocation);
  yield takeLatest(
    actions.createClientLocation.type,
    handleCreateClientLocation
  );
  yield takeLatest(
    actions.updateLocationStatus.type,
    handleUpdateLocationStatus
  );
}
