import React from "react";
import { IconButton } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

const BackButton = ({ label = null, icon = null, routes, ...props }) => {
  const history = useHistory();
  const {routeInfo}=props;

  const location = useLocation();

  const routename= location.pathname;
  const findMatchingRoute = (routename) => {
    for (let route of routeInfo) {
      const fullPath = `${route.layout}${route.path}`;
      const pathParts = fullPath.split('/');
      const pathnameParts = routename.split('/');
  
      // Check if both have the same number of segments
      if (pathParts.length !== pathnameParts.length) continue;
  
      let isMatch = true;
      for (let i = 0; i < pathParts.length; i++) {
        // Check if the dynamic (starts with ':') or it matches the static
        if (!pathParts[i].startsWith(':') && pathParts[i] !== pathnameParts[i]) {
          isMatch = false;
          break;
        }
      }
  
      // If all parts match, return the route
      if (isMatch) {
        return route;
      }
    }
  
    return null;  // No matching route
  };
  
  // Find and log the matching route
  const matchingRoute = findMatchingRoute(routename);
  const isBackbtn = matchingRoute?.isBackBtn;

  const backButtonHandler = () => {
    const restrictedPathRegex = /^\/admin\/workflows\/[a-zA-Z0-9\-._~!$&'()*+,;=:@%]+$/;
    const historyPath = history.location.pathname

    if (restrictedPathRegex.test(historyPath)) {
      console.log({lok : history.location})
      history.replace("/admin/workflows");
    } else {
      return history.goBack();
    }
  };

  return (
    <>
      { isBackbtn && (<IconButton
          onClick={backButtonHandler}
          size="sm"
          p="0px 12px"
          border="1px solid #E2E8F0"
          borderRadius="8px"
          variant="outline"
          icon={icon}
        />) }
      </>
  );
};

export default BackButton;