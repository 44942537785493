import React, { useEffect, useState } from "react";
import { HStack, Input, IconButton } from "@chakra-ui/react";
import { MdOutlineNumbers } from "react-icons/md";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import { MdOutlineDeleteOutline } from "react-icons/md";

export default function Number({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
}) {
  const range = data?.element?.range;
  if (data.element.repeatable?.isRepeatable || repeatable?.isRepeatable) {
    const minEntries = data.element.repeatable?.minEntries;
    const maxEntries = data.element.repeatable?.maxEntries;
    const unlimitedEntries = data.element.repeatable?.unlimitedEntries;

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
      });
    }
    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
            })),
            //This part will be removed after the testing is verified.
            // {
            //   order:
            //     formData[data.element.id]?.attachments[
            //       formData[data.element.id]?.attachments?.length - 1
            //     ]?.order + 1,
            //   value: "",
            // },
          ]
        : initialValues
    );

    const handleAddMore = () => {
      if (
        !values[values.length - 1].value ||
        (values.length >= maxEntries && !unlimitedEntries)
      )
        return;
      setValues((prevValues) => [
        ...prevValues,
        { order: prevValues[prevValues.length - 1].order + 1, value: "" },
      ]);
    };

    const handleDelete = (order) => {
      if (values.length < 2 || values.length <= minEntries) return;
      const newVals = values.filter((value) => value.order !== order);
      setValues(newVals);
      handleFormDataChange(data.element.id, "repeatable-delete", order - 1);
    };

    const elementData = formData[data.element.id]?.attachments;
    useEffect(() => {
      if (elementData?.length) {
        setValues(
          elementData.map((value, index) => ({
            order: index + 1,
            value,
          }))
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            { target: { name: data.element.id, value: value.value } },
            "repeatable",
            value.order
          );
        });
      }
    }, [elementData]);

    if (repeatable?.isRepeatable) {
      const value = values[index];
      return (
        <HStack w="100%" mb="8px">
          <HStack
            w="100%"
            h="48px"
            style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
          >
            <HStack w="48px" h="48px" justify="center" align="center">
              <MdOutlineNumbers size={18} style={{ fill: "#718096" }} />{" "}
            </HStack>
            <Input
              type="text"
              variant="unstyled"
              inputMode="decimal"
              placeholder={data.element.placeholder || "Number"}
              required={data.element.mandatory}
              color="#2D3748"
              _placeholder={{ color: "#A0AEC0" }}
              name={data.element.id}
              value={elementData[index]}
              onChange={(e) => {
                if (range) {
                  const inputValue = e.target.value;
                  const fromValue = range?.from;
                  const toValue = range?.to;
                  if (
                    inputValue === "" ||
                    (toValue?.length === inputValue?.length &&
                      parseFloat(fromValue) <= parseFloat(inputValue) &&
                      parseFloat(toValue) >= parseFloat(inputValue))
                  ) {
                    handleFormDataChange(e, "repeatable", index + 1);
                  } else if (
                    toValue?.length !== inputValue?.length &&
                    parseFloat(inputValue) <= parseFloat(toValue)
                  ) {
                    handleFormDataChange(e, "repeatable", index + 1);
                  }
                } else {
                  handleFormDataChange(e, "repeatable", index + 1);
                }
              }}
              onInput={(e) => {
                const inputValue = e.target.value;
                const decimalCount = (inputValue.match(/\./g) || []).length;
                if (decimalCount <= 1) {
                  e.target.value = inputValue.replace(/[^0-9.]/g, "");
                } else {
                  e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
                }
              }}
              onBlur={(e) => {
                if (range) {
                  if (parseFloat(e.target.value) < parseFloat(range.from)) {
                    e.target.value = parseFloat(range.from);
                    handleFormDataChange(e, "repeatable", index + 1);
                  }
                }
              }}
            />
          </HStack>
        </HStack>
      );
    }

    return (
      <>
        {values &&
          values.map((value, index) => (
            <HStack w="100%" mb="8px" key={value.order}>
              <HStack
                w="100%"
                h="48px"
                style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
              >
                <HStack w="48px" h="48px" justify="center" align="center">
                  <MdOutlineNumbers size={18} style={{ fill: "#718096" }} />
                </HStack>
                <Input
                  type="text"
                  variant="unstyled"
                  inputMode="decimal"
                  placeholder={data.element.placeholder || "Number"}
                  required={data.element.mandatory}
                  color="#2D3748"
                  _placeholder={{ color: "#A0AEC0" }}
                  name={data.element.id}
                  value={elementData[index]}
                  onChange={(e) => {
                    if (range) {
                      const inputValue = e.target.value;
                      const fromValue = range?.from;
                      const toValue = range?.to;
                      if (
                        inputValue === "" ||
                        (toValue?.length === inputValue?.length &&
                          parseFloat(fromValue) <= parseFloat(inputValue) &&
                          parseFloat(toValue) >= parseFloat(inputValue))
                      ) {
                        handleFormDataChange(e, "repeatable", index + 1);
                      } else if (
                        toValue?.length !== inputValue?.length &&
                        parseFloat(inputValue) <= parseFloat(toValue)
                      ) {
                        handleFormDataChange(e, "repeatable", index + 1);
                      }
                    } else {
                      handleFormDataChange(e, "repeatable", index + 1);
                    }
                  }}
                  onInput={(e) => {
                    const inputValue = e.target.value;
                    const decimalCount = (inputValue.match(/\./g) || []).length;
                    if (decimalCount <= 1) {
                      e.target.value = inputValue.replace(/[^0-9.]/g, "");
                    } else {
                      e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
                    }
                  }}
                  onBlur={(e) => {
                    if (range) {
                      if (parseFloat(e.target.value) < parseFloat(range.from)) {
                        e.target.value = parseFloat(range.from);
                        handleFormDataChange(e, "repeatable", index + 1);
                      }
                    }
                  }}
                />
              </HStack>
              <IconButton
                variant="ghost"
                borderRadius="8px"
                isDisabled={values.length < 2 || values.length <= minEntries}
                sx={{
                  ".icon": {
                    color: "red.500",
                  },
                }}
                icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                onClick={() => {
                  handleDelete(value.order);
                }}
              />
            </HStack>
          ))}
        <Button
          size="sm"
          borderRadius="8px"
          onClick={handleAddMore}
          leftIcon={<AddIcon />}
          label="Add More"
          isDisabled={
            !values[values.length - 1].value ||
            (values.length >= maxEntries && !unlimitedEntries)
          }
        />
      </>
    );
  }
  return (
    <HStack
      w="100%"
      h="48px"
      style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
    >
      <HStack w="48px" h="48px" justify="center" align="center">
        <MdOutlineNumbers size={18} style={{ fill: "#718096" }} />
      </HStack>
      <Input
        variant="unstyled"
        type="text"
        inputMode="decimal"
        placeholder={data.element.placeholder}
        required={data.element.mandatory}
        color="#2D3748"
        _placeholder={{ color: "#A0AEC0" }}
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={(e) => {
          if (range) {
            const inputValue = e.target.value;
            const fromValue = range?.from;
            const toValue = range?.to;
            if (
              inputValue === "" ||
              (toValue?.length === inputValue?.length &&
                parseFloat(fromValue) <= parseFloat(inputValue) &&
                parseFloat(toValue) >= parseFloat(inputValue))
            ) {
              handleFormDataChange(e);
            } else if (
              toValue?.length !== inputValue?.length &&
              parseFloat(inputValue) <= parseFloat(toValue)
            ) {

              handleFormDataChange(e);
            }
          } else {
            handleFormDataChange(e);
          }
        }}
        onInput={(e) => {
          const inputValue = e.target.value;
          const decimalCount = (inputValue.match(/\./g) || []).length;
          if (decimalCount <= 1) {
            e.target.value = inputValue.replace(/[^0-9.]/g, "");
          } else {
            e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
          }
        }}
        onBlur={(e) => {
          if (range) {
            if (parseFloat(e.target.value) < parseFloat(range.from)) {
              e.target.value = parseFloat(range.from);
              handleFormDataChange(e);
            }
          }
        }}
      />
    </HStack>
  );
}
