import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  InputGroup,
  InputLeftAddon,
  VStack,
  Image,
  ModalBody,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { selectAllCUstomerLinks } from "views/Customer/selectors";
import AddAdditionalAddress from "views/shared/AddAdditionalAddress";
import { BasicInfo } from "views/shared/BasicInfo";
import Select from "views/shared/Select";
import { actions } from "../../slice";
import { useDispatch } from "react-redux";
import { generateIdSync } from "utils";
import AddLocation from "views/shared/AddLocation";
import Action from "assets/svg/action.svg";
import { Table } from "@agnext/reactlib";
import { EditIcon } from "components/Icons/Icons";
import Edit from "assets/svg/edit-icon.svg";
import deleteIcon from "assets/svg/deleteRed.svg";
import { ActionIcon } from "components/Icons/Icons";
import { onlyNumber } from "utils/helper";

const styles = {
  width: "280px",
  padding: "20px",
  borderRadius: "15px",
  position: "absolute",
  boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
  zIndex: "15",
};

const myStyles = {
  background: "white",
  boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
  borderRadius: 15,
};

const flexStyles = {
  gap: "18px",
  flexDirection: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};
const countryCodes = ["India +91", "UAE +971"];
const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
}) => {
  return (
    <Flex direction="column" gap="8px" minWidth="300px" flex="2">
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
        position="static"
      />

      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};

const headers = [
  {
    displayText: "Name",
    keyText: "name",
    hasSort: false,
  },

  {
    displayText: "Contact Person",
    keyText: "contactPerson",
    hasSort: false,
  },
  {
    displayText: "Email",
    keyText: "email",
    hasSort: false,
  },
  {
    displayText: "Phone Number",
    keyText: "phoneNumber",
    hasSort: true,
  },
  {
    displayText: "Location Type",
    keyText: "locationType",
  },
  {
    displayText: "Address",
    keyText: "address",
  },
  {
    displayText: " Status",
    keyText: "status",
  },
  {
    displayText: " Actions",
    keyText: "actions",
  },
];

const AddCustomerTop = ({
  customerDetails,
  handleInputChange,
  handleFileChange,
  profileImagePreview,
  handleRegistrationInfo,
  registrationInfo,
  handleLinksChange,
  links,
  handleRegisteredAddress,
  registeredAddress,
  handleAdditionalAddress,
  additionalAddress,
  avatarUrl,
  setLinks,
  emailIsValid,
  setEmailIsValid,
  panIsValid,
  setPanIsValid,
  cinIsValid,
  setCinIsValid,
  tinIsValid,
  setTinIsValid,
  trnIsValid,
  setTrnIsValid,
  clnIsValid,
  setClnIsValid,
  phoneIsValid,
  setPhoneIsValid,
  tempAddress,
  setTempAddress,
  isEditing,
  oldCustomerAddresses,
  isChanged,
  setIsChanged,
  pinCodeIsValid,
  setPinCodeIsValid,
  countryCode,
  setCountryCode,
  setRegisteredAddress,
  locations,
  oldCustomerDetails,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const oldAdditionalAddress =
    oldCustomerAddresses &&
    oldCustomerAddresses.filter((info) => !info.isRegistered);

  const {
    isOpen: isOpenAddress,
    onOpen: onOpenAddress,
    onClose: onCloseAddress,
  } = useDisclosure();

  const {
    isOpen: delAddAddressIsOpen,
    onOpen: openDelAddAddress,
    onClose: closeDelAddAddress,
  } = useDisclosure();

  const handleEmailChange = (email) => {
    handleLinksChange(0, email);
    setEmailIsValid(validateEmail(email));
  };
  const linkTypeCheck = (clientEmailPhone, check) => {
    return clientEmailPhone?.filter((linkObj) => linkObj.linkType === check);
  };

  const handleInputClick = () => {
    document.getElementById("input_profile_img").click();
  };

  // const businessTypes = ["Inspection Agency", "Clients Directives"];
  const countries = ["India", "UAE"];

  const [country, setCountry] = useState("");

  useEffect(() => {
    if (customerDetails.country) {
      setCountry(customerDetails.country);
    }
  }, [customerDetails]);

  const handleTempOptionChange = (value, customKey) => {
    handleTempAddress(customKey, value);
  };
  const handleTempAddress = (field, value) => {
    if (!isChanged) setIsChanged(true);
    if (field === "state") {
      setTempAddress({
        ...tempAddress,
        postalCode: null,
        city: "",
        [field]: value,
      });
      dispatch(
        actions.fetchLocationData({
          id: additionalAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          state: value,
        })
      );
    } else if (field === "city") {
      setTempAddress({
        ...tempAddress,
        postalCode: null,
        [field]: value,
      });
      dispatch(
        actions.fetchLocationData({
          id: additionalAddress?.id,
          country:
            customerDetails?.country === "UAE"
              ? "United Arab Emirates"
              : customerDetails?.country,
          state: tempAddress?.state,
          city: value,
        })
      );
    } else {
      setTempAddress({
        ...tempAddress,
        [field]: value,
      });
    }
  };

  const handleAddAddressButton = () => {
    if (!isChanged) setIsChanged(true);
    handleAdditionalAddress((prevValues) => {
      return {
        ...prevValues,
        ...tempAddress,
        country,
        postalCode: country === "UAE" ? null : tempAddress.postalCode,
      };
    });
    onCloseAddress();
  };

  // validate phone number
  const validatePhone = (phone, code) => {
    let tempCode = code ? code : countryCode;
    if (!phone && !tempCode) return true;
    if (!phone) return true;
    if (!tempCode) return false;
    if (tempCode === "India +91") {
      // Indian mobile number validation
      const pattern = /^[6-9]\d{9}$/;
      return pattern.test(phone);
    } else if (tempCode === "UAE +971") {
      // UAE mobile number validation
      const pattern = /^[0-9]{9}$/;
      return pattern.test(phone);
    }
    return false;
  };

  const handleOptionChange = (value, customKey) => {
    // if (customKey === "businessType") {
    //   handleInputChange(customKey, value);
    // }
    if (customKey === "country") {
      if (links[1].value === "") {
        const newCountryCode =
          value !== "" ? (value === "India" ? "India +91" : "UAE +971") : "";
        //
        const code = value !== "" ? (value === "India" ? 91 : 971) : null;
        setLinks((prevLinks) => {
          return prevLinks.map((prevLink) => {
            if (prevLink.type === "phone")
              return { ...prevLink, countryCode: code };
            return { ...prevLink };
          });
        });
        //
        setCountry(value);
        setCountryCode(newCountryCode);
      }
      handleInputChange(customKey, value);
      //for locaiton service
      setRegisteredAddress((prev) => {
        return {
          ...prev,
          postalCode: null,
          city: "",
          state: "",
        };
      });

      setTempAddress((prev) => {
        return {
          ...prev,
          postalCode: null,
          city: "",
          state: "",
        };
      });

      dispatch(actions.clearLocationState());
      // if (links && links.length && linkTypeCheck(links, "phone").length) {

      // links?.map((link) => {
      //   if (link.type === "phone" && link.value !== "") {
      //     setPhoneIsValid(validatePhoneWithCountry(link.value, value));
      //   }
      // });

      // if (links && links[1].value) {
      //   setPhoneIsValid(validatePhoneWithCountry(links[1].value, value));
      // }
      // else {
      //   setPhoneIsValid(false);
      // }
    } else if (customKey === "countryCode") {
      const code = !value ? null : value === "India +91" ? 91 : 971;
      setLinks((prevLinks) => {
        return prevLinks.map((prevLink) => {
          if (prevLink.type === "phone")
            return { ...prevLink, countryCode: code };
          return { ...prevLink };
        });
      });
      if (value) {
        setPhoneIsValid(validatePhone(links[1].value, value));
      } else {
        setPhoneIsValid(false);
      }
      setCountryCode(value);
    } else if (customKey === "registeredState") {
      handleRegisteredAddress("state", value);
    } else if (customKey === "registeredCity") {
      handleRegisteredAddress("city", value);
    }
  };

  const [
    additionalAddressPinIsValid,
    setAdditionalAddressPinIsValid,
  ] = useState(true);

  const isDisabled = () => {
    return (
      Object.entries(tempAddress).some(([key, value]) => {
        if (customerDetails.country === "India")
          return key !== "addressLine2" && (value === "" || value === null);
        else
          return key !== "addressLine2" && key !== "postalCode" && value === "";
      }) || !additionalAddressPinIsValid
    );
  };
  // useEffect(() => {
  //   if (country) {
  //     let countryCode = country.toLowerCase() === "india" ? 91 : 971;
  //     setLinks((prevLinks) => {
  //       return prevLinks.map((prevLink) => {
  //         if (prevLink.type === "phone") return { ...prevLink, countryCode };
  //         return { ...prevLink };
  //       });
  //     });
  //   }
  // }, [country]);
  // Method for delete Additional Address
  const handleDelAdditionalAddress = () => {
    const customerId = oldCustomerDetails?.id;
    const oldAddressId =
      oldAdditionalAddress.length > 0
        ? oldAdditionalAddress[0]?.customerAddressId
        : null;
    dispatch(
      actions.deleteAdditionalAddress({
        customerId,
        oldAddressId,
        onSuccess: (status) => {
          if (status === true) {
            toast({
              title: " Additional Address deleted successfully",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            if (!isChanged) setIsChanged(true);
            handleAdditionalAddress((prev) => {
              return {
                ...prev,
                type: "",
                addressLine1: "",
                addressLine2: "",
                postalCode: null,
                city: "",
                state: "",
                isRegistered: false,
              };
            });
            setTempAddress({
              type: "",
              addressLine1: "",
              addressLine2: "",
              postalCode: null,
              city: "",
              state: "",
              isRegistered: false,
            });
          }
        },
        onFailure: () => {
          toast({
            title: "Error in deleting address",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (
      Object.entries(tempAddress).every(([key, value]) => {
        if (key === "isRegistered") {
          return true; // Skip this key
        }
        return value === "" || value === null;
      })
    ) {
      handleAdditionalAddress((prev) => {
        return {
          ...prev,
          type: "",
          addressLine1: "",
          addressLine2: "",
          postalCode: null,
          city: "",
          state: "",
          country: "",
          isRegistered: true,
        };
      });
    }
  }, [tempAddress]);

  const regState = locations[registeredAddress?.id]?.states || [];
  const regCity = locations[registeredAddress?.id]?.cities || [];
  const addState = locations[additionalAddress?.id]?.states || [];
  const addCity = locations[additionalAddress?.id]?.cities || [];

  return (
    <>
      <Stack spacing={"24px"}>
        <Box>
          <Text fontWeight={600}>Basic Info</Text>
        </Box>
        <Box sx={myStyles}>
          <Box padding={"16px 24px"}>
            <Flex gap={"32px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Agency Details
              </Text>
              <Flex direction={{ base: "column", md: "row" }} gap={"32px"}>
                <Flex direction={"column"} gap={"12px"}>
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    Upload Logo
                  </Text>
                  <Box>
                    <Input
                      id="input_profile_img"
                      display={"none"}
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Box
                      width="120px"
                      height="120px"
                      border="2px dashed #E2E8F0"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      onClick={handleInputClick}
                    >
                      {profileImagePreview ? (
                        <img
                          src={profileImagePreview}
                          alt="Profile Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <LuUploadCloud
                          size="24px"
                          color="#718096"
                          position={"absolute"}
                        />
                      )}
                    </Box>
                  </Box>
                </Flex>
                <Flex flexWrap="wrap" gap="8px">
                  <Flex direction={"column"} gap={"8px"} minW="300px" flex="2">
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      Country *
                    </Text>
                    <Select
                      customKey="country"
                      options={countries}
                      placeholder="Select"
                      value={customerDetails.country}
                      handleOptionChange={handleOptionChange}
                      isDisabled={isEditing}
                    />
                  </Flex>
                  <CustomInput
                    label="Organization Name*"
                    placeholder="eg. ABC Pvt Ltd"
                    value={customerDetails.orgName}
                    onChange={(value) => handleInputChange("orgName", value)}
                  />
                  {/* <Flex
                      direction={"column"}
                      gap={"8px"}
                      flex={{ base: "1", md: "1" }}
                    >
                      <Text fontSize={"12px"} fontWeight={"700"}>
                        Business Type
                      </Text>
                      <Select
                        customKey="businessType"
                        options={businessTypes}
                        placeholder="Select"
                        value={customerDetails.businessType}
                        handleOptionChange={handleOptionChange}
                      />
                    </Flex> */}
                  <CustomInput
                    label="Email*"
                    placeholder="eg. abc@gmail.com"
                    value={links[0].value}
                    // onChange={(value) => handleLinksChange(0, value)}
                    onChange={(value) => handleEmailChange(value)}
                    isValid={emailIsValid}
                    errorMessage="Invalid email format"
                  />
                  <Flex direction="column" gap="8px" minW="300px" flex="2">
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      Phone Number*
                    </Text>
                    <InputGroup>
                      <InputLeftAddon p={"0"} br={"2px"} border={"none"}>
                        <Select
                          placeholder="(+XX)"
                          customKey="countryCode"
                          value={countryCode}
                          handleOptionChange={handleOptionChange}
                          options={countryCodes}
                          minWidth="120px"
                          maxWidth="120px"
                        />
                      </InputLeftAddon>
                      <Input
                        type={"text"}
                        placeholder={
                          countryCode === "UAE +971"
                            ? "eg. 967 000 ####"
                            : "eg. 96 000 ####"
                        }
                        value={links[1].value}
                        alignSelf="stretch"
                        focusBorderColor="#3182ce"
                        fontSize="14px"
                        onChange={(e) => {
                          setPhoneIsValid(validatePhone(e.target.value));
                          handleLinksChange(1, e.target.value);
                        }}
                        onInput={onlyNumber}
                      />
                    </InputGroup>
                    {!phoneIsValid && (
                      <Text fontSize="12px" color="red.500">
                        {!countryCode
                          ? "Please select Country code"
                          : "Please enter valid mobile number"}
                      </Text>
                    )}
                  </Flex>

                  {country === "India" && (
                    <CustomInput
                      label="GSTIN*"
                      placeholder="eg. 12ABCDE1234F1Z5"
                      value={registrationInfo.TIN}
                      onChange={(value) => {
                        setTinIsValid(validateTIN(value));
                        handleRegistrationInfo("TIN", value);
                      }}
                      isValid={tinIsValid}
                      errorMessage="Please enter valid GSTIN"
                    />
                  )}
                  {country === "India" && (
                    <CustomInput
                      label="CIN*"
                      placeholder="eg. L12345AB1234XYZ789013"
                      value={registrationInfo.CIN}
                      onChange={(value) => {
                        setCinIsValid(validateCIN(value));
                        handleRegistrationInfo("CIN", value);
                      }}
                      isValid={cinIsValid}
                      errorMessage="Invalid CIN format"
                    />
                  )}
                  {country === "India" && (
                    <CustomInput
                      label="Buisness PAN*"
                      placeholder="eg. BWJDF6473A"
                      value={registrationInfo.PAN}
                      onChange={(value) => {
                        setPanIsValid(validatePAN(value));
                        handleRegistrationInfo("PAN", value);
                      }}
                      isValid={panIsValid}
                      errorMessage="Invalid PAN Number"
                    />
                  )}
                  {country === "UAE" && (
                    <CustomInput
                      label="Commercial Licence No*"
                      placeholder="eg. U74999DL2021PTC12####"
                      value={registrationInfo.CLN}
                      onChange={(value) => {
                        setClnIsValid(validateCLN(value));
                        handleRegistrationInfo("CLN", value);
                      }}
                      isValid={clnIsValid}
                      errorMessage="Invalid CLN Number"
                    />
                  )}
                  {country === "UAE" && (
                    <CustomInput
                      label="TRN*"
                      placeholder="eg. 123456789012345"
                      value={registrationInfo.TAN}
                      onChange={(value) => {
                        setTrnIsValid(validateTRN(value));
                        handleRegistrationInfo("TAN", value);
                      }}
                      isValid={trnIsValid}
                      errorMessage="Invalid TRN Number"
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
        {/* Registered Address  */}
        <Box sx={myStyles}>
          <Box padding={"16px 24px"}>
            <Flex gap={"32px"} direction={"column"}>
              <Text
                sx={{ color: "#718096", fontSize: "14px", fontWeight: "600" }}
              >
                Registered Address
              </Text>
              <Flex direction={"column"} gap={"18px"}>
                <Flex sx={flexStyles}>
                  <CustomInput
                    label="Address Line 1*"
                    placeholder="eg. 1234 Design Avenue"
                    value={registeredAddress.addressLine1}
                    onChange={(value) =>
                      handleRegisteredAddress("addressLine1", value)
                    }
                  />
                  <CustomInput
                    label="Address Line 2"
                    placeholder="eg. Near ABC Shop"
                    value={registeredAddress.addressLine2}
                    onChange={(value) =>
                      handleRegisteredAddress("addressLine2", value)
                    }
                  />

                  <Flex
                    direction={"column"}
                    gap={"8px"}
                    flex={{ base: "1", md: "1" }}
                  >
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      State*
                    </Text>
                    <Select
                      placeholder={"Select"}
                      options={
                        customerDetails?.country ? [...regState].sort() : []
                      }
                      customKey="registeredState"
                      value={capitalizeStringUpdated(registeredAddress.state)}
                      handleOptionChange={handleOptionChange}
                      check={
                        customerDetails?.country
                          ? " "
                          : "Please select country first"
                      }
                    />
                  </Flex>
                </Flex>
                <Flex sx={flexStyles}>
                  <Flex
                    direction={"column"}
                    gap={"8px"}
                    flex={{ base: "1", md: "1" }}
                  >
                    <Text fontSize={"12px"} fontWeight={"700"}>
                      City*
                    </Text>
                    <Select
                      customKey="registeredCity"
                      placeholder={"Select"}
                      options={
                        customerDetails?.country && registeredAddress?.state
                          ? [...regCity].sort()
                          : []
                      }
                      value={capitalizeStringUpdated(registeredAddress?.city)}
                      handleOptionChange={handleOptionChange}
                      check={
                        customerDetails?.country && registeredAddress?.state
                          ? " "
                          : "Please select state first"
                      }
                    />
                  </Flex>

                  {country === "India" && (
                    <Flex
                      direction={"column"}
                      gap={"8px"}
                      flex={{ base: "1", md: "1" }}
                    >
                      <CustomInput
                        label="Pin Code*"
                        placeholder="eg. 160XXX"
                        value={registeredAddress.postalCode}
                        onChange={(value) => {
                          setPinCodeIsValid(validatePINCode(value));
                          handleRegisteredAddress("postalCode", value);
                        }}
                        type={"number"}
                        isValid={pinCodeIsValid}
                        errorMessage="Please enter valid Pin Code"
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
        {/* Additional Address  */}
        <Flex direction={"column"} gap={"36px"} color={"#2D3748"}>
          <Stack spacing={"24px"}>
            <Box>
              <Text fontWeight={700} fontSize={"18px"}>
                Additional Address
              </Text>
            </Box>
            {additionalAddress.addressLine1 !== "" ? (
              <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                <Box
                  style={{
                    background: "white",
                    boxShadow:
                      "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                    borderRadius: 15,
                    flex: "1",
                  }}
                >
                  <Box padding={"16px 24px"}>
                    <BasicInfo
                      handleDeleteButton={openDelAddAddress}
                      isEditing={isEditing}
                      onOpenAddress={onOpenAddress}
                      displayText={capitalizeStringUpdated(
                        [
                          additionalAddress?.addressLine1,
                          additionalAddress?.addressLine2?.trim(),
                          additionalAddress?.city,
                          additionalAddress?.state,
                          additionalAddress?.postalCode,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      )}
                      oldAddress={oldAdditionalAddress}
                      // showDeleteBtn={false}
                    />
                  </Box>
                </Box>
              </Flex>
            ) : (
              <Button
                size="lg"
                height="48px"
                width="100%"
                borderColor="white"
                isDisabled={country === ""}
                bg="white" // Set the background color to white
                onClick={onOpenAddress}
                borderRadius="8px"
              >
                + Add Address
              </Button>
            )}
          </Stack>
          <Stack spacing={"24px"}></Stack>
        </Flex>
      </Stack>
      <Modal
        isOpen={isOpenAddress}
        onClose={onCloseAddress}
        size={"4xl"}
        isCentered
      >
        <ModalOverlay />

        <ModalContent w={"100%"} color="#2D3748" borderRadius="15px">
          <ModalHeader fontSize="18px" fontWeight={"700"}>
            Additional Address
          </ModalHeader>
          <ModalCloseButton />
          <AddAdditionalAddress
            customerDetails={customerDetails}
            handleTempAddress={handleTempAddress}
            tempAddress={tempAddress}
            handleOptionChange={handleTempOptionChange}
            country={country}
            additionalAddressPinIsValid={additionalAddressPinIsValid}
            setAdditionalAddressPinIsValid={setAdditionalAddressPinIsValid}
            validatePINCode={validatePINCode}
            states={addState}
            cities={addCity}
          />
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={onCloseAddress}
              color={"black"}
            >
              Cancel
            </Button>

            <Button
              colorScheme="teal"
              onClick={handleAddAddressButton}
              isDisabled={isDisabled()}
            >
              Add Address
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* delete modal for Additinal Address  */}
      <Modal
        isOpen={delAddAddressIsOpen}
        onClose={closeDelAddAddress}
        isCentered
      >
        <ModalOverlay />

        <ModalContent maxW="436px">
          <ModalHeader fontWeight={700} fontSize={"18px"}>
            Confirm Delete
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="#718096">
              Are you certain you want to proceed with deletion?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="modalsLight"
              mr={3}
              onClick={closeDelAddAddress}
              color={"black"}
            >
              Close
            </Button>

            <Button
              colorScheme="modalsDark"
              onClick={() => {
                handleDelAdditionalAddress();
                closeDelAddAddress();
              }}
            >
              Yes, Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCustomerTop;

const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};
const validatePAN = (pan) => {
  const pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return pattern.test(pan);
};

const validateTIN = (gstin) => {
  const pattern = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/;
  return pattern.test(gstin);
};

const validateCIN = (cin) => {
  const pattern = /[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
  return pattern.test(cin);
};

const validateTRN = (trn) => {
  const pattern = /^[0-9]{15}$/;
  return pattern.test(trn);
};

const validateCLN = (cln) => {
  // Assuming CLN is a number with a specific length
  const pattern = /^[\dA-Z]+$/;
  return pattern.test(cln.replace(/\s/g, ""));
};

const validatePINCode = (pin) => {
  const pattern = /^\d{6}$/;
  return pattern.test(pin);
};
