export const isModified = (val) => {
  if (Array.isArray(val)) {
    // If val is an array, check if any item has modified as true
    return val.some(
      (item) =>
        item.modified === true ||
        item.modified === "Deleted" ||
        item.modified === "Added"
    )
      ? { modified: true }
      : { modified: false };
  } else if (typeof val === "object" && val !== null) {
    // If val is an object, check its modified property
    return { modified: val.modified };
  }
  return false;
};
export const convertArrayToObject = (arr, index) => {
  const result = {};

  arr.forEach((item) => {
    const templateKey = Object.keys(item)[0];
    const entries = item[templateKey];

    if (entries && entries[index]) {
      const entry = entries[index];
      const data = entry.data;
      result[data.id] = data;
    }
  });

  return result;
};
export const transformObjectKeys = (data) => {
  return data.map((item) => {
    // Get the current object key (since there's only one key at the top level)
    const oldKey = Object.keys(item)[0];

    // Extract the value of templateItemId
    const newKey = item[oldKey].templateItemId;

    // Create a new object with the templateItemId as the key and the object as the value
    return { [newKey]: item[oldKey] };
  });
};
export const mergeModifiedStatus = (dbDataArray, hederaArray) => {
  const maxLength = Math.max(dbDataArray.length, hederaArray.length);
  const result = [];
  for (let i = 0; i < maxLength; i++) {
    const localItem = dbDataArray[i] || {};
    const remoteItem = hederaArray[i] || {};
    result.push({
      modified: localItem.modified || remoteItem.modified || false,
    });
  }
  return result;
};
