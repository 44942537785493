export const masterConfig = {
  desktopLargeLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: null,
          filters: [
            {
              id: "commodity_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "commodity",
            },
            {
              id: "location_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "location",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: null,
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "totalInspections_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections",
                  colOrder: 1,
                },
                {
                  id: "quantityInspected_22",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "numberOfBags_31",
                  size: { w: 1, h: 1 },
                  dataSource: "numberOfBags",
                  colOrder: 1,
                },
                {
                  id: "commodities_32",
                  size: { w: 1, h: 1 },
                  dataSource: "commodities",
                  colOrder: 2,
                },
                {
                  id: "sitesInspected_33",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "inspectionCount_41",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "commodityHealth_51",
                  size: { w: 1, h: 1 },
                  dataSource: "commodityHealth",
                  colOrder: 1,
                },
                {
                  id: "measuredCommodities_52",
                  size: { w: 1, h: 1 },
                  dataSource: "measuredCommodities",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "warehouseScore_61",
                  size: { w: 1, h: 1 },
                  dataSource: "warehouseScore",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "geographicalReach_71",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReach",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  desktopLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: null,
          filters: [
            {
              id: "commodity_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "commodity",
            },
            {
              id: "location_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "location",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: null,
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "totalInspections_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections",
                  colOrder: 1,
                },
                {
                  id: "quantityInspected_22",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "numberOfBags_31",
                  size: { w: 1, h: 1 },
                  dataSource: "numberOfBags",
                  colOrder: 1,
                },
                {
                  id: "commodities_32",
                  size: { w: 1, h: 1 },
                  dataSource: "commodities",
                  colOrder: 2,
                },
                {
                  id: "sitesInspected_33",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "inspectionCount_41",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "commodityHealth_51",
                  size: { w: 1, h: 1 },
                  dataSource: "commodityHealth",
                  colOrder: 1,
                },
                {
                  id: "measuredCommodities_52",
                  size: { w: 1, h: 1 },
                  dataSource: "measuredCommodities",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "warehouseScore_61",
                  size: { w: 1, h: 1 },
                  dataSource: "warehouseScore",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "geographicalReach_71",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReach",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  tabletLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: null,
          filters: [
            {
              id: "commodity_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "commodity",
            },
            {
              id: "location_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "location",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: null,
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "totalInspections_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "quantityInspected_31",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "numberOfBags_41",
                  size: { w: 1, h: 1 },
                  dataSource: "numberOfBags",
                  colOrder: 1,
                },
                {
                  id: "commodities_42",
                  size: { w: 1, h: 1 },
                  dataSource: "commodities",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "sitesInspected_51",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "inspectionCount_61",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "commodityHealth_71",
                  size: { w: 1, h: 1 },
                  dataSource: "commodityHealth",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "measuredCommodities_81",
                  size: { w: 1, h: 1 },
                  dataSource: "measuredCommodities",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 9,
              widgets: [
                {
                  id: "warehouseScore_91",
                  size: { w: 1, h: 1 },
                  dataSource: "warehouseScore",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 10,
              widgets: [
                {
                  id: "geographicalReach_101",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReach",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  mobileLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: null,
          filters: [
            {
              id: "mobileDateRangePicker_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "mobileDateRangePicker",
            },
          ],
          secondaryFilters: [
            {
              id: "commodity_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "commodity",
            },
            {
              id: "location_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "location",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: null,
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "totalInspections_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "quantityInspected_31",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "numberOfBags_41",
                  size: { w: 1, h: 1 },
                  dataSource: "numberOfBags",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "commodities_51",
                  size: { w: 1, h: 1 },
                  dataSource: "commodities",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "sitesInspected_61",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "inspectionCount_71",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "commodityHealth_81",
                  size: { w: 1, h: 1 },
                  dataSource: "commodityHealth",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 9,
              widgets: [
                {
                  id: "measuredCommodities_91",
                  size: { w: 1, h: 1 },
                  dataSource: "measuredCommodities",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 10,
              widgets: [
                {
                  id: "warehouseScore_101",
                  size: { w: 1, h: 1 },
                  dataSource: "warehouseScore",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 11,
              widgets: [
                {
                  id: "geographicalReach_101",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReach",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
};

export const masterConfig2 = {
  webLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "executive_summary",
          filters: [
            {
              id: "season_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "season",
            },
            {
              id: "commodity_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "commodity",
            },
            {
              id: "variety_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "variety",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "executive_summary",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "header_21",
                  size: { w: 1, h: 1 },
                  dataSource: "Procurement Summary",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "quantityInspectedNCCF1_31",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected1",
                  colOrder: 1,
                },
                {
                  id: "quantityAcceptedNCCF1_32",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted1",
                  colOrder: 2,
                },
                {
                  id: "quantityUnacceptedNCCF1_33",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted1",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "tableSimple_41",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "header_51",
                  size: { w: 1, h: 1 },
                  dataSource: "Loading Summary",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "trucksInspectedNCCF1_61",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksInspected1",
                  colOrder: 1,
                },
                {
                  id: "trucksAcceptedNCCF1_62",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksAccepted1",
                  colOrder: 2,
                },
                {
                  id: "trucksUnacceptedNCCF1_63",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksUnaccepted1",
                  colOrder: 3,
                },
                {
                  id: "samplingNotDoneNCCF1_64",
                  size: { w: 1, h: 1 },
                  dataSource: "samplingNotDone1",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "quantityInspectedNCCF2_71",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected2",
                  colOrder: 1,
                },
                {
                  id: "quantityAcceptedNCCF2_72",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted2",
                  colOrder: 2,
                },
                {
                  id: "quantityUnacceptedNCCF2_73",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted2",
                  colOrder: 3,
                },
                {
                  id: "bagsInspectedNCCF1_73",
                  size: { w: 1, h: 1 },
                  dataSource: "bagsInspected1",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "tableSimple_71",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableLoading",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "header_81",
                  size: { w: 1, h: 1 },
                  dataSource: "Unloading Summary",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 9,
              widgets: [
                {
                  id: "trucksInspectedNCCF2_91",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksInspected2",
                  colOrder: 1,
                },
                {
                  id: "trucksAcceptedNCCF2_92",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksAccepted2",
                  colOrder: 2,
                },
                {
                  id: "trucksUnacceptedNCCF2_93",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksUnaccepted2",
                  colOrder: 3,
                },
                {
                  id: "samplingNotDoneNCCF2_94",
                  size: { w: 1, h: 1 },
                  dataSource: "samplingNotDone2",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 10,
              widgets: [
                {
                  id: "quantityInspectedNCCF3_101",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected3",
                  colOrder: 1,
                },
                {
                  id: "quantityAcceptedNCCF3_102",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted3",
                  colOrder: 2,
                },
                {
                  id: "quantityUnacceptedNCCF3_103",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted3",
                  colOrder: 3,
                },
                {
                  id: "bagsInspectedNCCF2_104",
                  size: { w: 1, h: 1 },
                  dataSource: "bagsInspected2",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 11,
              widgets: [
                {
                  id: "tableSimple_111",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableUnloading",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        {
          tabName: "procurement_summary",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "numberOfInspectionsNCCF1_21",
                  size: { w: 1, h: 1 },
                  dataSource: "numberOfInspections1",
                  colOrder: 2,
                },
                {
                  id: "quantityInspectedNCCF1_21",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected1",
                  colOrder: 2,
                },
                {
                  id: "quantityAcceptedNCCF1_23",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted1",
                  colOrder: 3,
                },
                {
                  id: "quantityUnacceptedNCCF1_24",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted1",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "barChart_31",
                  size: { w: 1, h: 1 },
                  dataSource: "barChartProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "tableSimple_41",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_42",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        {
          tabName: "loading_summary",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "trucksInspectedNCCF1_21",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksInspected1",
                  colOrder: 1,
                },
                {
                  id: "trucksAcceptedNCCF1_22",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksAccepted1",
                  colOrder: 2,
                },
                {
                  id: "trucksUnacceptedNCCF1_23",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksUnaccepted1",
                  colOrder: 3,
                },
                {
                  id: "samplingNotDoneNCCF1_24",
                  size: { w: 1, h: 1 },
                  dataSource: "samplingNotDone1",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "quantityInspectedNCCF2_31",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected2",
                  colOrder: 1,
                },
                {
                  id: "quantityAcceptedNCCF2_32",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted2",
                  colOrder: 2,
                },
                {
                  id: "quantityUnacceptedNCCF2_33",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted2",
                  colOrder: 3,
                },
                {
                  id: "bagsInspectedNCCF1_34",
                  size: { w: 1, h: 1 },
                  dataSource: "bagsInspected1",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "barChart_41",
                  size: { w: 1, h: 1 },
                  dataSource: "barChartLoading",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "tableSimple_51",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_52",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        {
          tabName: "unloading_summary",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "trucksInspectedNCCF2_21",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksInspected2",
                  colOrder: 1,
                },
                {
                  id: "trucksAcceptedNCCF2_22",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksAccepted2",
                  colOrder: 2,
                },
                {
                  id: "trucksUnacceptedNCCF2_23",
                  size: { w: 1, h: 1 },
                  dataSource: "trucksUnaccepted2",
                  colOrder: 3,
                },
                {
                  id: "samplingNotDoneNCCF2_24",
                  size: { w: 1, h: 1 },
                  dataSource: "samplingNotDone2",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "quantityInspectedNCCF3_31",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityInspected3",
                  colOrder: 1,
                },
                {
                  id: "quantityAcceptedNCCF3_32",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityAccepted3",
                  colOrder: 2,
                },
                {
                  id: "quantityUnacceptedNCCF3_33",
                  size: { w: 1, h: 1 },
                  dataSource: "quantityUnaccepted3",
                  colOrder: 3,
                },
                {
                  id: "bagsInspectedNCCF2_34",
                  size: { w: 1, h: 1 },
                  dataSource: "bagsInspected2",
                  colOrder: 4,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "barChart_41",
                  size: { w: 1, h: 1 },
                  dataSource: "barChartUnloading",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "tableSimple_51",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_52",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        {
          tabName: "FPO_and_FPC_performance",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "tableSimple_21",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_22",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "tableSimple_31",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_32",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "tableSimple_41",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
                {
                  id: "tableSimple_42",
                  size: { w: 1, h: 1 },
                  dataSource: "summaryTableProcurement",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  mobileLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      widgetDefaultSize: { width: 10, height: 10 },
      filters: ["commodity", "location", "dateRangePicker"],
      dashboard: [
        {
          id: "filters",
          row: 1,
          columns: 1,
          properties: { widthMultiplier: 4 },
        },
        { id: "insightsAndGraphs", row: 2, columns: 1 },
        { id: "insightData", row: 3, columns: 1 },
        { id: "inspectionCount", row: 4, columns: 1 },
        { id: "commodityHealth", row: 5, columns: 1 },
        { id: "measuredCommodity", row: 5, columns: 2 },
        { id: "warehouseScore", row: 6, columns: 1 },
        { id: "geographicalReach", row: 7, columns: 1 },
      ],
    },
  },
};

export const masterConfig3 = {
  webLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        // {
        //   tabName: "auditor_performance",
        //   filters: [
        //     {
        //       id: "auditor_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "auditor",
        //     },
        //     {
        //       id: "client_f2",
        //       colOrder: 2,
        //       size: { w: 1, h: 1 },
        //       dataSource: "client",
        //     },
        //     {
        //       id: "dateRangePicker_f3",
        //       colOrder: 3,
        //       size: { w: 1, h: 1 },
        //       dataSource: "dateRangePicker",
        //     },
        //   ],
        //   filtersActions: [],
        // },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },

                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 3,
                },
                {
                  id: "dataCard_24",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 4,
                },
                {
                  id: "dataCard_25",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 5,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 2,
                },
                {
                  id: "dataCard_33",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "barchartCount_41",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "geographicalReachWithFilter_71",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 2,
                },
                {
                  id: "dataCard_24",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "entityTable_31",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  tabletLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "auditor_performance",
          filters: [
            {
              id: "auditor_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "client_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },
                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 3,
                },
              ],
              rowProps: {
                widthPercentage: 100,
                gap: 16,
              },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_42",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 2,
                },
                {
                  id: "dataCard_43",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "barchartCount_51",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "geographicalReachWithFilter_61",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "entityTable_41",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
    // {
    // widgetDefaultSize: { width: 10, height: 10 },
    // filters: ["commodity", "location", "dateRangePicker"],
    // dashboard: [
    //   {
    //     id: "filters",
    //     row: 1,
    //     columns: 1,
    //     properties: { widthMultiplier: 4 },
    //   },
    //   { id: "insightsAndGraphs", row: 2, columns: 1 },
    //   { id: "insightData", row: 3, columns: 1 },
    //   { id: "inspectionCount", row: 4, columns: 1 },
    //   { id: "commodityHealth", row: 5, columns: 1 },
    //   { id: "measuredCommodity", row: 5, columns: 2 },
    //   { id: "warehouseScore", row: 6, columns: 1 },
    //   { id: "geographicalReach", row: 7, columns: 1 },
    // ],
    // },
  },
  mobileLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "mobileDateRangePicker_f1",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "mobileDateRangePicker",
            },
          ],
          secondaryFilters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
          ],
          filtersActions: [],
        },
        // {
        //   tabName: "auditor_performance",
        //   filters: [
        //     {
        //       id: "dateRangePicker_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "dateRangePicker",
        //     },
        //   ],
        //   secondaryFilters: [
        //     {
        //       id: "auditor_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "auditor",
        //     },
        //     {
        //       id: "client_f2",
        //       colOrder: 2,
        //       size: { w: 1, h: 1 },
        //       dataSource: "client",
        //     },
        //     ],
        //   filtersActions: [],
        // },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "mobileDateRangePicker_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "mobileDateRangePicker",
            },
          ],
          secondaryFilters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },
              ],
              rowProps: {
                widthPercentage: 100,
                gap: 16,
              },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "dataCard_51",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "dataCard_61",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "dataCard_71",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "barchartCount_81",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 9,
              widgets: [
                {
                  id: "geographicalReachWithFilter_91",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "entityTable_41",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
    // {
    // widgetDefaultSize: { width: 10, height: 10 },
    // filters: ["commodity", "location", "dateRangePicker"],
    // dashboard: [
    //   {
    //     id: "filters",
    //     row: 1,
    //     columns: 1,
    //     properties: { widthMultiplier: 4 },
    //   },
    //   { id: "insightsAndGraphs", row: 2, columns: 1 },
    //   { id: "insightData", row: 3, columns: 1 },
    //   { id: "inspectionCount", row: 4, columns: 1 },
    //   { id: "commodityHealth", row: 5, columns: 1 },
    //   { id: "measuredCommodity", row: 5, columns: 2 },
    //   { id: "warehouseScore", row: 6, columns: 1 },
    //   { id: "geographicalReach", row: 7, columns: 1 },
    // ],
    // },
  },
};

export const masterConfig4 = {
  desktopLargeLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        // {
        //   tabName: "auditor_performance",
        //   filters: [
        //     {
        //       id: "auditor_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "auditor",
        //     },
        //     {
        //       id: "client_f2",
        //       colOrder: 2,
        //       size: { w: 1, h: 1 },
        //       dataSource: "client",
        //     },
        //     {
        //       id: "dateRangePicker_f3",
        //       colOrder: 3,
        //       size: { w: 1, h: 1 },
        //       dataSource: "dateRangePicker",
        //     },
        //   ],
        //   filtersActions: [],
        // },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },

                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 3,
                },
                {
                  id: "dataCard_24",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 4,
                },
                {
                  id: "dataCard_25",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 5,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 2,
                },
                {
                  id: "dataCard_33",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "barchartCount_41",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "geographicalReachWithFilter_71",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 2,
                },
                {
                  id: "dataCard_24",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "entityTable_31",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
  },
  desktopLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "auditor_performance",
          filters: [
            {
              id: "auditor_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "client_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },
                {
                  id: "dataCard_23",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 3,
                },
              ],
              rowProps: {
                widthPercentage: 100,
                gap: 16,
              },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_42",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 2,
                },
                {
                  id: "dataCard_43",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 3,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "barchartCount_51",
                  size: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "geographicalReachWithFilter_61",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "entityTable_41",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
    // {
    // widgetDefaultSize: { width: 10, height: 10 },
    // filters: ["commodity", "location", "dateRangePicker"],
    // dashboard: [
    //   {
    //     id: "filters",
    //     row: 1,
    //     columns: 1,
    //     properties: { widthMultiplier: 4 },
    //   },
    //   { id: "insightsAndGraphs", row: 2, columns: 1 },
    //   { id: "insightData", row: 3, columns: 1 },
    //   { id: "inspectionCount", row: 4, columns: 1 },
    //   { id: "commodityHealth", row: 5, columns: 1 },
    //   { id: "measuredCommodity", row: 5, columns: 2 },
    //   { id: "warehouseScore", row: 6, columns: 1 },
    //   { id: "geographicalReach", row: 7, columns: 1 },
    // ],
    // },
  },
  tabletLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "dateRangePicker_f4",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "auditor_performance",
          filters: [
            {
              id: "auditor_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
            {
              id: "client_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "dateRangePicker_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "dateRangePicker",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 2,
                },
              ],
              rowProps: {
                widthPercentage: 100,
                gap: 16,
              },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "dataCard_51",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_52",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 2,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "dataCard_61",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "barchartCount_71",
                  props: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "geographicalReachWithFilter_81",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
                {
                  id: "dataCard_22",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 1,
                },
                {
                  id: "dataCard_32",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 2,
                },
              ],
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "entityTable_41",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
    // {
    // widgetDefaultSize: { width: 10, height: 10 },
    // filters: ["commodity", "location", "dateRangePicker"],
    // dashboard: [
    //   {
    //     id: "filters",
    //     row: 1,
    //     columns: 1,
    //     properties: { widthMultiplier: 4 },
    //   },
    //   { id: "insightsAndGraphs", row: 2, columns: 1 },
    //   { id: "insightData", row: 3, columns: 1 },
    //   { id: "inspectionCount", row: 4, columns: 1 },
    //   { id: "commodityHealth", row: 5, columns: 1 },
    //   { id: "measuredCommodity", row: 5, columns: 2 },
    //   { id: "warehouseScore", row: 6, columns: 1 },
    //   { id: "geographicalReach", row: 7, columns: 1 },
    // ],
    // },
  },
  mobileLayout: {
    leftArea: {},
    topArea: {},
    bottomArea: {},
    rightArea: {},
    centralArea: {
      filtersObj: [
        {
          tabName: "overview",
          filters: [
            {
              id: "mobileDateRangePicker_f1",
              colOrder: 4,
              size: { w: 1, h: 1 },
              dataSource: "mobileDateRangePicker",
            },
          ],
          secondaryFilters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspection_type_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
            {
              id: "auditor_f3",
              colOrder: 3,
              size: { w: 1, h: 1 },
              dataSource: "auditor",
            },
          ],
          filtersActions: [],
        },
        // {
        //   tabName: "auditor_performance",
        //   filters: [
        //     {
        //       id: "dateRangePicker_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "dateRangePicker",
        //     },
        //   ],
        //   secondaryFilters: [
        //     {
        //       id: "auditor_f1",
        //       colOrder: 1,
        //       size: { w: 1, h: 1 },
        //       dataSource: "auditor",
        //     },
        //     {
        //       id: "client_f2",
        //       colOrder: 2,
        //       size: { w: 1, h: 1 },
        //       dataSource: "client",
        //     },
        //     ],
        //   filtersActions: [],
        // },
        {
          tabName: "client_statistics",
          filters: [
            {
              id: "mobileDateRangePicker_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "mobileDateRangePicker",
            },
          ],
          secondaryFilters: [
            {
              id: "client_f1",
              colOrder: 1,
              size: { w: 1, h: 1 },
              dataSource: "client",
            },
            {
              id: "inspectionType_f2",
              colOrder: 2,
              size: { w: 1, h: 1 },
              dataSource: "inspectionType",
            },
          ],
          filtersActions: [],
        },
      ],
      dashboard: [
        {
          tabName: "overview",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "scheduled",
                  colOrder: 1,
                },
              ],
              rowProps: {
                widthPercentage: 100,
                gap: 16,
              },
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "inProgress",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "overdue",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "dataCard_51",
                  size: { w: 1, h: 1 },
                  dataSource: "inReview",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "dataCard_61",
                  size: { w: 1, h: 1 },
                  dataSource: "approved",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 7,
              widgets: [
                {
                  id: "dataCard_71",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_overview", //changed"
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 8,
              widgets: [
                {
                  id: "dataCard_81",
                  size: { w: 1, h: 1 },
                  dataSource: "sitesInspected_overview", //changed
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 9,
              widgets: [
                {
                  id: "dataCard_91",
                  props: { w: 1, h: 1 },
                  dataSource: "clientsInspected",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 10,
              widgets: [
                {
                  id: "barchartCount_101",
                  props: { w: 1, h: 1 },
                  dataSource: "inspectionCount_overview",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 11,
              widgets: [
                {
                  id: "geographicalReachWithFilter_111",
                  props: { w: 1, h: 1 },
                  dataSource: "geographicalReachWithFilter",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
          ],
        },
        // {
        //   tabName: "auditor_performance",
        //   rows: [
        //     {
        //       rowOrder: 1,
        //       widgets: [
        //         {
        //           id: "filters_11",
        //           size: { w: 1, h: 1 },
        //           dataSource: "",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 16 },
        //     },
        //     {
        //       rowOrder: 2,
        //       widgets: [
        //         {
        //           id: "dataCard_21",
        //           size: { w: 1, h: 1 },
        //           dataSource: "totalInspections",
        //           colOrder: 1,
        //         },
        //         {
        //           id: "dataCard_22",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageOnTimeCompletionRate",
        //           colOrder: 2,
        //         },
        //         {
        //           id: "dataCard_23",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageTimePerInspection",
        //           colOrder: 3,
        //         },
        //         {
        //           id: "dataCard_24",
        //           size: { w: 1, h: 1 },
        //           dataSource: "averageReviewerRating",
        //           colOrder: 4,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //     {
        //       rowOrder: 3,
        //       widgets: [
        //         {
        //           id: "entityTable_31",
        //           size: { w: 1, h: 1 },
        //           dataSource: "auditorsForTable",
        //           colOrder: 1,
        //         },
        //       ],
        //       rowProps: { widthPercentage: 100, gap: 24 },
        //     },
        //   ],
        // },
        {
          tabName: "client_statistics",
          rows: [
            {
              rowOrder: 1,
              widgets: [
                {
                  id: "filters_11",
                  size: { w: 1, h: 1 },
                  dataSource: "",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 16 },
            },
            {
              rowOrder: 2,
              widgets: [
                {
                  id: "dataCard_21",
                  size: { w: 1, h: 1 },
                  dataSource: "totalInspections_client_statistics", //changed
                  colOrder: 1,
                },
              ],
            },
            {
              rowOrder: 3,
              widgets: [
                {
                  id: "dataCard_31",
                  size: { w: 1, h: 1 },
                  dataSource: "numAuditors",
                  colOrder: 1,
                },
              ],
            },
            {
              rowOrder: 4,
              widgets: [
                {
                  id: "dataCard_41",
                  size: { w: 1, h: 1 },
                  dataSource: "averageOnTimeCompletionRate",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
            {
              rowOrder: 5,
              widgets: [
                {
                  id: "dataCard_51",
                  size: { w: 1, h: 1 },
                  dataSource: "averageTimePerInspection",
                  colOrder: 1,
                },
              ],
            },
            {
              rowOrder: 6,
              widgets: [
                {
                  id: "entityTable_61",
                  size: { w: 1, h: 1 },
                  dataSource: "clientsForTable",
                  colOrder: 1,
                },
              ],
              rowProps: { widthPercentage: 100, gap: 24 },
            },
          ],
        },
      ],
    },
    // {
    // widgetDefaultSize: { width: 10, height: 10 },
    // filters: ["commodity", "location", "dateRangePicker"],
    // dashboard: [
    //   {
    //     id: "filters",
    //     row: 1,
    //     columns: 1,
    //     properties: { widthMultiplier: 4 },
    //   },
    //   { id: "insightsAndGraphs", row: 2, columns: 1 },
    //   { id: "insightData", row: 3, columns: 1 },
    //   { id: "inspectionCount", row: 4, columns: 1 },
    //   { id: "commodityHealth", row: 5, columns: 1 },
    //   { id: "measuredCommodity", row: 5, columns: 2 },
    //   { id: "warehouseScore", row: 6, columns: 1 },
    //   { id: "geographicalReach", row: 7, columns: 1 },
    // ],
    // },
  },
};


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatConfig(config, layout) {
  const tabs = [];
  // sorting
  config[layout]?.centralArea?.filtersObj?.map((filterObj) =>
    filterObj.filters.sort((a, b) => a.colOrder - b.colOrder)
  );
  config[layout]?.centralArea?.dashboard?.map((tab) =>
    tab.rows.sort((a, b) => a.rowOrder - b.rowOrder)
  );
  config[layout]?.centralArea?.dashboard?.map((tab) =>
    tab.rows.map((row) => row.widgets.sort((a, b) => a.colOrder - b.colOrder))
  );
  config[layout]?.centralArea?.dashboard?.forEach((tab) => {
    if (tab.tabName) {
      let tabId = tab.tabName;
      let tabValue = tabId
        .split("_")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");
      tabs.push({ id: tabId, value: tabValue });
    }
  });
  return { config: config[layout], tabs };
}
