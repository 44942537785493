import { Box, Button, Flex, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Switch, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TemplateDownload from "../../../../views/BulkUpload/components/TemplateDownload";
import UploadFileModal from "../../../../views/BulkUpload/components/UploadFileModal";
import RegisterAddress from "views/shared/RegisterAddress";
import uploadIcon from "../../../../assets/svg/upload.svg";
import Edit from "assets/svg/edit-icon.svg";
import deleteIcon from "assets/svg/deleteRed.svg";
import locationBlack from "assets/svg/locationBlack.svg";
import AddLocation from "views/shared/AddLocation";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { generateIdSync } from "utils";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../selectors.js";
import { actions } from "../../slice";
import { EditIcon } from "components/Icons/Icons";
import { ActionIcon } from "components/Icons/Icons";
import { Table } from "@agnext/reactlib";
import { validateExternalCode, validateEmail, validatePhone } from "utils/validationFunctions";
import BulkOriginCreation from "views/BulkUpload/BulkOriginCreation";


export const BasicInfo = ({ displayText, onOpenAddress, isEditing, openDeleteModal, address }) => {
    return (
        <Flex justifyContent="space-between">
            <Flex gap={"11px"} flex={"1"} justifyContent={{ base: "space-between", md: "flex-start" }} alignItems="center">
                <Flex gap={"10px"}>
                    <Image src={locationBlack} alt="Dan Abramov" />{" "}
                    {displayText && (
                        <Text
                            sx={{
                                color: "#2D3748",
                                fontWeight: location ? 400 : 500,
                                fontSize: "14px",
                            }}
                        >
                            {displayText}
                        </Text>
                    )}
                </Flex>
            </Flex>
            <Flex gap="12px">
                {isEditing && address && address.length ? (
                    <Box background={"white"} height={"32px"} width={"38px"} display="flex" justifyContent="center" alignItems="center" borderRadius={"12px"} border={"1px solid #E53E3E"} style={{ cursor: "pointer" }} onClick={openDeleteModal}>
                        <Image src={deleteIcon} alt="" />
                    </Box>
                ) : (
                    <></>
                )}
                <Box background={"#EDF2F7"} height={"32px"} width={"38px"} display="flex" justifyContent="center" alignItems="center" borderRadius={"12px"} style={{ cursor: "pointer" }} onClick={onOpenAddress}>
                    <Image src={Edit} alt="" />
                </Box>
            </Flex>
        </Flex>
    );
};

const styles = {
    width: "280px",
    padding: "20px",
    borderRadius: "15px",
    position: "absolute",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    zIndex: "15",
};

const headers = [
    {
        displayText: "Name",
        keyText: "name",
        hasSort: false,
    },
    {
        displayText: "Warehouse Code",
        keyText: "externalCode",
        hasSort: false,
    },
    {
        displayText: "Contact Person",
        keyText: "contactPerson",
        hasSort: true,
    },
    {
        displayText: "Email",
        keyText: "email",
        hasSort: true,
    },
    {
        displayText: "Phone Number",
        keyText: "phoneNumber",
    },
    {
        displayText: "Location Type",
        keyText: "locationType",
        hasSort: true,
    },
    {
        displayText: "Address",
        keyText: "address",
        hasSort: true,
    },
    {
        displayText: "Status",
        keyText: "status",
    },
    {
        displayText: "Actions",
        keyText: "actions",
    },
];

const AddClientLocation = ({
    // isDisabled,
    handleRegisteredAddress,
    registeredAddress,
    isEditing,
    setIsAddressDeleted,
    isAddressDeleted,
    handleDeleteButton,
    clientDetails,
    tempAddress,
    setTempAddress,
    setIsChanged,
    isChanged,
    clientAddress,
    pinCodeIsValid,
    setPinCodeIsValid,
    locations,
    validatePINCode,
    locationArray,
    locationCompareArray,
    clientId,
    refreshLocations,
}) => {

    const avatarUrl = useSelector(selectors.selectAvatarUrl);
    const toast = useToast();
    const dynamicToast = (message, { status = "success", duration = 1000, isClosable = true }) => {
        toast({
            title: message,
            status: status,
            duration: duration,
            isClosable: isClosable,
        });
    };

    const dispatch = useDispatch();
    const { isOpen: isOpenLocation, onOpen: onOpenLocation, onClose: onCloseLocation } = useDisclosure();
    const { isOpen: isOpenAddress, onOpen: onOpenAddress, onClose: onCloseAddress } = useDisclosure();

    const { isOpen: deleteIsOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

    const { isOpen: deleteLocationIsOpen, onOpen: openDeleteLocationModal, onClose: closeDeleteLocationModal } = useDisclosure();

    const { isOpen: activateLocationIsOpen, onOpen: openActivateLocationModal, onClose: closeActivateLocationModal } = useDisclosure();

    const { isOpen: deactivateLocationIsOpen, onOpen: openDeactivateLocationModal, onClose: closeDeactivateLocationModal } = useDisclosure();

    const [isValidLocationPINCode, setIsValidLocationPINCode] = useState(true);
    const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);
    const [isValidLocationEmail, setIsValidLocationEmail] = useState(true);
    const [isValidExternalCode, setIsValidExternalCode] = useState(true);
    const [idToDelete, setIdToDelete] = useState(null);
    const [idToEdit, setIdToEdit] = useState(null);
    const [idToChangeStatus, setIdToChangeStatus] = useState(null);
    const [newLocation, setNewLocation] = useState({
        id: generateIdSync(),
        name: "",
        externalCode: null,
        area: null,
        additionalProperties: {},
        city: "",
        contactPerson: "",
        originType: "",
        addressLine1: "",
        addressLine2: "",
        postalCode: "",
        state: "",
        links: [
            { value: null, type: "phone", countryCode: "" },
            { value: null, type: "email", countryCode: null },
        ],
        isEditing: false,
        isFromDB: false,
        isChanged: false,
    });

    const [isOpen, setIsOpen] = useState(false);
    const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
        x: 0,
        y: 0,
    });


    const resetNewLocationState = () => {
        setNewLocation({
            id: generateIdSync(),
            name: "",
            externalCode: null,
            area: null,
            additionalProperties: {},
            city: "",
            contactPerson: "",
            originType: "",
            addressLine1: "",
            addressLine2: "",
            postalCode: "",
            state: "",
            links: [
                { value: null, type: "phone", countryCode: "" },
                { value: null, type: "email", countryCode: null },
            ],
            isEditing: false,
            isFromDB: false,
            isChanged: false,
        });
    };
    const handleAddLocation = () => {
        if (isEditing) {
            if (newLocation.isFromDB) {
                let locationPayload = { ...newLocation };
                const originId = locationPayload.id;
                delete locationPayload.id;
                delete locationPayload.isFromDB;
                delete locationPayload.isEditing;
                delete locationPayload.isChanged;
                let manager = { id: clientId, type: "client" };

                const updatedLinks = locationPayload.links?.map((link) => {
                    if (link.value === "") {
                        return { ...link, value: null, countryCode: null };
                    } else {
                        return link;
                    }
                });
                locationPayload.links = updatedLinks;

                locationPayload.managers = [manager];
                dispatch(
                    actions.updateClientLocation({
                        locationPayload,
                        originId,
                        onSuccess: () => {
                            dynamicToast("Location Updation Successfully", {
                                status: "success",
                            });
                            refreshLocations();
                        },
                        onFailure: () => {
                            dynamicToast("Failed to Update Location", { status: "error" });
                        },
                    })
                );
            } else {
                let locationPayload = { ...newLocation };
                delete locationPayload.id;
                delete locationPayload.isFromDB;
                delete locationPayload.isEditing;
                let manager = { id: clientId, type: "client" };

                const updatedLinks = locationPayload.links?.map((link) => {
                    if (link.value === "") {
                        return { ...link, value: null, countryCode: null };
                    } else {
                        return link;
                    }
                });
                locationPayload = {
                    ...locationPayload,
                    country: clientDetails.country,
                    managers: [manager],
                    links: updatedLinks,
                };

                dispatch(
                    actions.createClientLocation({
                        locationPayload,
                        onSuccess: () => {
                            dynamicToast("Location Added Successfully", {
                                status: "success",
                            });
                            refreshLocations();
                        },
                        onFailure: () => {
                            dynamicToast("Failed to Add Location", { status: "error" });
                        },
                    })
                );
            }

            resetNewLocationState();
            setIdToEdit(null);
            setIdToDelete(null);
        }
    };

    const handleDeleteLocation = () => {
        dispatch(
            actions.deleteLocation({
                idToDelete,
                onSuccess: () => {
                    dynamicToast("Location Deleted Successfully", {
                        status: "success",
                    });
                    refreshLocations();
                },
                onFailure: () => {
                    dynamicToast("Failed to Delete Location", { status: "error" });
                },
            })
        );
    };

    const handleLocationStatus = (status) => {
        console.log({ status });
        dispatch(
            actions.updateLocationStatus({
                originId: idToChangeStatus,
                managerId: clientId,
                status,
                onSuccess: () => {
                    dynamicToast("Status Changed Successfully", {
                        status: "success",
                    });
                    refreshLocations();
                },
                onFailure: () => {
                    dynamicToast("Failed to Change Location Status", { status: "error" });
                },
            })
        );
    };

    const handleOptionChange = (value, customKey) => {
        if (customKey === "state") {
            dispatch(
                actions.fetchLocationData({
                    id: registeredAddress?.id,
                    country: clientDetails?.country === "UAE" ? "United Arab Emirates" : clientDetails?.country,
                    state: value,
                    // city: tempAddress?.city,
                })
            );
        } else if (customKey === "city") {
            dispatch(
                actions.fetchLocationData({
                    id: registeredAddress?.id,
                    country: clientDetails?.country === "UAE" ? "United Arab Emirates" : clientDetails?.country,
                    state: tempAddress?.state,
                    city: value,
                })
            );
        }
        handleTempAddress(customKey, value);
    };
    const handleTempAddress = (field, value) => {
        // if (!isChanged) setIsChanged(true);
        if (field === "state" && value === "") {
            setTempAddress((prev) => {
                return {
                    ...prev,
                    city: "",
                    pinCode: "",
                    [field]: value,
                };
            });
        } else if (field === "city" && value === "") {
            setTempAddress((prev) => {
                return {
                    ...prev,
                    city: "",
                    pinCode: "",
                    [field]: value,
                };
            });
        } else {
            setTempAddress((prev) => {
                return {
                    ...prev,
                    [field]: value,
                };
            });
        }
    };

    const handleAddAddressButton = () => {
        if (!isChanged) setIsChanged(true);
        handleRegisteredAddress((prevValues) => {
            return {
                ...prevValues,
                ...tempAddress,
                country: clientDetails?.country,
                pinCode: clientDetails?.country === "UAE" ? null : tempAddress.pinCode,
            };
        });
        onCloseAddress();
    };

    const isDisabled = () => {
        return (
            Object.entries(tempAddress).some(([key, value]) => {
                if (clientDetails.country === "India") return key !== "addressLine2" && (value === "" || value === null);
                else return key !== "addressLine2" && key !== "pinCode" && value === "";
            }) || !pinCodeIsValid
        );
    };

    useEffect(() => {
        if (
            Object.values(tempAddress).every((value) => {
                return value === "" || value === null;
            })
        ) {
            handleRegisteredAddress((prev) => {
                return {
                    ...prev,
                    address: "",
                    addressLine2: null,
                    pinCode: null,
                    city: "",
                    state: "",
                    country: "",
                    isRegistered: true,
                    isActive: true,
                };
            });
        }
    }, [tempAddress]);

    const handleNewLocationInputChange = (e) => {
        const { name, value } = e.target;
        setNewLocation((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "externalCode") {
            if (!value) setIsValidExternalCode(true);
            else setIsValidExternalCode(validateExternalCode(value, 5, 30, /^[a-zA-Z0-9/_-]+$/));
        }
        if (name === "postalCode") {
            if (!value) setIsValidLocationPINCode(true);
            else setIsValidLocationPINCode(validateNewLocationPINCode(value));
        }
    };

    const handleLinksChange = (e, type) => {
        console.log(e.target.value, type);
        if (type === "phone") {
            setIsValidMobileNumber(validatePhone(e.target.value, newLocation?.links?.find((link) => link.type === "phone")?.countryCode ? (newLocation?.links?.find((link) => link.type === "phone")?.countryCode === "91" ? "India +91" : "UAE +971") : ""));
        } else {
            setIsValidLocationEmail(validateEmail(e.target.value));
        }

        const updatedLinks = (newLocation.links || []).map((link) => {
            if (link.type === type) {
                return { ...link, value: e.target.value };
            }
            return link;
        });
        setNewLocation((prevState) => ({
            ...prevState,
            links: updatedLinks,
        }));
    };
    const handleNewLocationSelectChange = (value, type) => {
        if (type === "countryCode") {
            let code = value ? (value === "India +91" ? "91" : "971") : "";
            const updatedLinks = (newLocation.links || []).map((link) => {
                if (link.type === "phone") {
                    return { ...link, countryCode: code };
                }
                return link;
            });
            setNewLocation((prevState) => ({
                ...prevState,
                links: updatedLinks,
            }));

            setIsValidMobileNumber(validatePhone(newLocation.links.find((link) => link.type === "phone")?.value ?? "", value));
        } else {
            setNewLocation((prevState) => ({
                ...prevState,
                [type]: value,
            }));
        }
        if (type === "state") {
            dispatch(
                actions.fetchLocationData({
                    country: clientDetails?.country === "UAE" ? "United Arab Emirates" : clientDetails?.country,
                    id: newLocation?.id,
                    state: value,
                    city: newLocation.city,
                })
            );
            if (!value) setNewLocation((prev) => ({ ...prev, city: "", postalCode: "" }));
        }
        if (type === "city") {
            dispatch(
                actions.fetchLocationData({
                    country: clientDetails?.country === "UAE" ? "United Arab Emirates" : clientDetails?.country,
                    id: newLocation?.id,
                    state: newLocation.state,
                    city: value,
                })
            );
            if (!value) setNewLocation((prev) => ({ ...prev, city: "", postalCode: "" }));
        }
    };

    useEffect(() => {
        if (clientDetails && clientDetails?.country) {
            dispatch(
                actions.fetchLocationData({
                    country: clientDetails?.country === "UAE" ? "United Arab Emirates" : clientDetails?.country,
                    id: newLocation?.id,
                    state: newLocation.state,
                    city: newLocation.city,
                })
            );
        }
    }, [clientDetails.country, newLocation?.id]);

    useEffect(() => {
        if (newLocation?.postalCode?.length === 6) {
            dispatch(
                actions.fetchLocationData({
                    id: newLocation?.id,
                    postalCode: newLocation?.postalCode,
                })
            );
        }
    }, [newLocation.postalCode]);

    useEffect(() => {
        if ((locations[newLocation?.id]?.states?.length === 1 && locations[newLocation?.id]?.cities?.length) === 1) {
            setNewLocation((prev) => {
                return {
                    ...prev,
                    city: locations[newLocation?.id]?.cities[0],
                    state: locations[newLocation?.id]?.states[0],
                };
            });
        }
    }, [locations]);

    useEffect(() => {
        dispatch(actions.fetchAvatarURL({payload:{payload:clientDetails?.avatar}}))
    }, [dispatch, clientId])

    const validateNewLocationPINCode = (pin) => {
        const pattern = /^\d{6}$/;

        return pattern.test(pin);
    };

    const addLocationButtonStatus = () => {
        let res = false;
        if (!newLocation.name || !newLocation.originType || !newLocation.state || !newLocation.city || (clientDetails?.country === "India" && !newLocation.postalCode) || !newLocation.addressLine1 || !isValidLocationEmail || !isValidMobileNumber || !isValidLocationPINCode || !isValidExternalCode) {
            res = true;
        }
        return res;
    };
    const updateLocationButtonStatus = () => {
        let res = true;
        const locationToCompare = locationCompareArray.find((location) => location.id === newLocation.id);

        const checkProperty = (prop) => {
            if (newLocation[prop] && newLocation[prop] !== locationToCompare[prop]) res = false;
        };

        checkProperty("name");
        checkProperty("originType");
        checkProperty("state");
        checkProperty("city");
        checkProperty("addressLine1");
        checkProperty("externalCode");
        if (newLocation.postalCode && newLocation.postalCode.length === 6 && newLocation.postalCode !== locationToCompare.postalCode) res = false;

        if (newLocation?.contactPerson !== locationToCompare?.contactPerson) res = false;

        if (newLocation?.addressLine2 !== locationToCompare?.addressLine2) res = false;

        newLocation?.links?.forEach((currLink) => {
            if (currLink.type === "email") {
                const existingEmailLink = locationToCompare?.links?.find((link) => link.type === "email");
                if (currLink?.value !== existingEmailLink?.value && isValidLocationEmail) {
                    res = false;
                }
            } else if (currLink.type === "phone") {
                const existingPhoneLink = locationToCompare?.links?.find((link) => link.type === "phone");
                if (currLink?.value !== existingPhoneLink?.value && isValidMobileNumber) {
                    res = false;
                }
                if (currLink?.countryCode !== existingPhoneLink?.countryCode && isValidMobileNumber) {
                    res = false;
                }
            }
        });
        if (addLocationButtonStatus()) res = true;
        return res;
    };

    const tableData =
        isEditing &&
        locationArray?.length &&
        locationArray
            .sort(({ name: a }, { name: b }) => (a > b ? 1 : b > a ? -1 : 0))
            .map((location) => {
                let phoneNumber = "-";
                const phoneLink = location?.links?.find((link) => link?.type === "phone");
                if (phoneLink?.value) {
                    phoneNumber = `+${phoneLink?.countryCode} ${phoneLink?.value}`;
                }
                return {
                    name: (
                        <Flex gap="8px">
                            <Image src={locationBlack}></Image>
                            <Text fontSize={"14px"}>{capitalizeStringUpdated(location?.name) || "-"}</Text>
                        </Flex>
                    ),
                    externalCode: location?.externalCode || "-",
                    contactPerson: location?.contactPerson || "-",
                    email: Array.isArray(location?.links) ? location?.links.find((link) => link?.type === "email")?.value ?? "" : "-",
                    phoneNumber,
                    locationType: capitalizeStringUpdated(location?.originType) || "-",
                    address: (location?.addressLine1 ? capitalizeStringUpdated(location?.addressLine1) : "") + (location?.addressLine2 ? "," + capitalizeStringUpdated(location?.addressLine2) : "") + (location?.city ? ", " + capitalizeStringUpdated(location?.city) : "") + (location?.state ? ", " + capitalizeStringUpdated(location?.state) : "") + (location?.postalCode ? ", " + location?.postalCode : ""),
                    status: (
                        <HStack gap="8px" alignItems="center">
                            <Text fontSize="12px" fontWeight="400" color="var(--gray-800, #1A202C)">
                                Deactivate
                            </Text>
                            <Switch
                                colorScheme="login"
                                onChange={() => {
                                    setIdToChangeStatus(location?.id);
                                    location?.status === "active" ? openDeactivateLocationModal() : openActivateLocationModal();
                                }}
                                isChecked={location?.status !== "active"}
                                size="sm"
                            />
                        </HStack>
                    ),
                    actions: (
                        <ActionIcon
                            w="20px"
                            h="20px"
                            mx="16px"
                            onClick={(e) => {
                                handleOpenModal(e);
                                setIdToDelete(location?.id);
                                setIdToEdit(location?.id);
                            }}
                            _hover={{
                                cursor: "pointer",
                            }}
                        />
                    ),
                };
            });

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    const handleOpenModal = (e) => {
        e.preventDefault(); // Prevent default link behavior
        e.stopPropagation();
        const iconPosition = e.currentTarget.getBoundingClientRect();

        setSelectedActionIconPosition({
            x: iconPosition.left,
            y: iconPosition.top,
        });
        setIsOpen(true);
    };


    return (
      <>
        <Flex direction={"column"} gap={"36px"}>
          <Stack spacing={"24px"}>
            <Box>
              <Text fontWeight={600}>Registered Address</Text>
            </Box>
            {registeredAddress.address !== "" ? (
              <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                <Box
                  style={{
                    background: "white",
                    boxShadow:
                      "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
                    borderRadius: 15,
                    flex: "1",
                  }}
                >
                  <Box padding={"16px 24px"}>
                    <BasicInfo
                      openDeleteModal={openDeleteModal}
                      isEditing={isEditing}
                      onOpenAddress={onOpenAddress}
                      displayText={capitalizeStringUpdated(
                        [
                          registeredAddress?.address,
                          registeredAddress?.addressLine2?.trim(),
                          registeredAddress?.city,
                          registeredAddress?.state,
                          registeredAddress?.pinCode,
                        ]
                          .filter(Boolean)
                          .join(", ")
                      )}
                      address={clientAddress}
                      showDeleteBtn={true}
                    />
                  </Box>
                </Box>
              </Flex>
            ) : (
              <Button
                size="lg"
                height="48px"
                width="100%"
                borderColor="white"
                isDisabled={clientDetails.country === ""}
                bg="white" // Set the background color to white
                onClick={onOpenAddress}
                borderRadius="8px"
              >
                + Add Address
              </Button>
            )}
          </Stack>
          {isEditing ? (
            <Stack spacing={"24px"}>
              <Box>
                <Text fontWeight={600}>Locations</Text>
              </Box>
              {locationArray?.length ? (
                <Box background={"white"} borderRadius={"12px"}>
                  <Table
                    overflow="auto"
                    overflowY="auto"
                    headerList={headers}
                    dataList={tableData}
                    stickyColumn={["Actions"]}
                    containerStyle={{ maxHeight: "300px" }}
                  />
                </Box>
              ) : (
                <></>
              )}
              <Flex direction="row" gap="24px">
                <Button
                  size="lg"
                  height="48px"
                  width="100%"
                  borderRadius={"10px"}
                  borderColor="white"
                  bg="white" // Set the background color to white
                  onClick={onOpenLocation}
                  isDisabled={clientDetails.country === ""}
                >
                  + Add Location
                </Button>
                <BulkOriginCreation
                  isDisabled={clientDetails.country === ""}
                  title={"Bulk Location Upload"}
                  manager={"Client"}
                  details={clientDetails}
                  avatar={avatarUrl?.url}
                  clientId={clientId}
                />
              </Flex>
            </Stack>
          ) : (
            <></>
          )}
        </Flex>

        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              handleCloseModal();
              setIdToDelete(null);
              setIdToEdit(null);
            }}
            sx={styles}
          >
            <ModalContent
              className="action-btn-dropdown"
              top={selectedActionIconPosition.y + -35 + "px"}
              left={selectedActionIconPosition.x + -308 + "px"}
            >
              <Box
                display="flex"
                rowGap="20px!important"
                flexDirection="column"
              >
                <Button
                  // display={jobDetails.canEdit ? 'none' : 'flex'}
                  className="download-report-btn"
                  variant="outline"
                  rightIcon={<EditIcon />}
                  onClick={() => {
                    let locationToEdit = locationArray.find(
                      (x) => x.id === idToEdit
                    );
                    if (locationToEdit) {
                      setNewLocation({
                        ...locationToEdit,
                        isEditing: true,
                      });
                      onOpenLocation();
                    }
                    handleCloseModal();
                  }}
                >
                  Edit Location
                </Button>
                <Button
                  className="download-report-btn"
                  variant="outline"
                  rightIcon={<Image mr="4px" src={deleteIcon} />}
                  onClick={() => {
                    openDeleteLocationModal();
                    handleCloseModal();
                  }}
                >
                  Delete Location
                </Button>
              </Box>
            </ModalContent>
          </Modal>
        )}

        <Modal
          isOpen={isOpenLocation}
          onClose={() => {
            onCloseLocation();
            resetNewLocationState();
          }}
          size={"4xl"}
        >
          <ModalOverlay />
          <ModalContent w={"100%"}>
            <ModalHeader>Add Location</ModalHeader>
            <ModalCloseButton />
            <AddLocation
              handleNewLocationInputChange={handleNewLocationInputChange}
              newLocation={newLocation}
              setNewLocation={setNewLocation}
              handleLinksChange={handleLinksChange}
              handleNewLocationSelectChange={handleNewLocationSelectChange}
              states={locations[newLocation?.id]?.states || []}
              cities={locations[newLocation?.id]?.cities || []}
              validateNewLocationPINCode={validateNewLocationPINCode}
              isValidLocationPINCode={isValidLocationPINCode}
              setIsValidLocationPINCode={setIsValidLocationPINCode}
              isValidMobileNumber={isValidMobileNumber}
              isValidLocationEmail={isValidLocationEmail}
              country={clientDetails.country}
              isValidExternalCode={isValidExternalCode}
            />
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={() => {
                  onCloseLocation();
                  resetNewLocationState();
                }}
                color={"black"}
              >
                Cancel
              </Button>

              <Button
                colorScheme="teal"
                onClick={() => {
                  handleAddLocation();
                  onCloseLocation();
                }}
                isDisabled={
                  newLocation.isEditing
                    ? updateLocationButtonStatus()
                    : addLocationButtonStatus()
                }
              >
                {newLocation.isEditing ? "Save Changes" : "Add Location"}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isOpenAddress}
          onClose={onCloseAddress}
          size={"4xl"}
          isCentered
        >
          <ModalOverlay />
          <ModalContent w={"100%"} color="#2D3748" borderRadius="15px">
            <ModalHeader fontSize="18px">Registered Address</ModalHeader>
            <ModalCloseButton />
            <RegisterAddress
              handleTempAddress={handleTempAddress}
              tempAddress={tempAddress}
              handleOptionChange={handleOptionChange}
              clientDetails={clientDetails}
              pinCodeIsValid={pinCodeIsValid}
              setPinCodeIsValid={setPinCodeIsValid}
              states={locations[registeredAddress?.id]?.states || []}
              cities={locations[registeredAddress?.id]?.cities || []}
              validatePINCode={validatePINCode}
            />
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={onCloseAddress}
                color={"black"}
              >
                Cancel
              </Button>

              <Button
                colorScheme="login"
                onClick={handleAddAddressButton}
                isDisabled={isDisabled()}
              >
                Add Address
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={deleteIsOpen} onClose={closeDeleteModal} isCentered>
          <ModalOverlay />

          <ModalContent maxW="436px">
            <ModalHeader fontWeight={700} fontSize={"18px"}>
              Confirm Delete
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text color="#718096">
                Are you certain you want to proceed with deletion?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={closeDeleteModal}
                color={"black"}
              >
                Close
              </Button>

              <Button
                colorScheme="modalsDark"
                onClick={() => {
                  handleDeleteButton();
                  closeDeleteModal();
                }}
              >
                Yes, Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={deleteLocationIsOpen}
          onClose={() => {
            setIdToDelete(null);
            closeDeleteLocationModal();
          }}
          onOpen={openDeleteLocationModal}
          isCentered
        >
          <ModalOverlay />

          <ModalContent maxW="436px">
            <ModalHeader fontWeight={700} fontSize={"18px"}>
              Confirm Delete
            </ModalHeader>
            <ModalCloseButton onClick={() => setIdToDelete(null)} />
            <ModalBody>
              <Text color="#718096">
                Are you certain you want to proceed with deletion?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={() => {
                  setIdToDelete(null);
                  closeDeleteLocationModal();
                }}
                color={"black"}
              >
                Close
              </Button>

              <Button
                colorScheme="modalsDark"
                onClick={() => {
                  handleDeleteLocation();
                  closeDeleteLocationModal();
                }}
              >
                Yes, Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={deactivateLocationIsOpen}
          onClose={() => {
            setIdToChangeStatus(null);
            closeDeactivateLocationModal();
          }}
          onOpen={openDeactivateLocationModal}
          isCentered
        >
          <ModalOverlay />

          <ModalContent maxW="436px">
            <ModalHeader fontWeight={700} fontSize={"18px"}>
              Deactivate Location
            </ModalHeader>
            <ModalCloseButton onClick={() => setIdToDelete(null)} />
            <ModalBody>
              <Text color="#319795" fontSize={"16px"}>
                When you deactivate this location, it will be temporarily
                suspended and inaccessible for any activities.
              </Text>
              <Text color="#319795" fontSize={"16px"} mt={"16px"}>
                Are you sure you want to proceed and deactivate this location?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={() => {
                  setIdToChangeStatus(null);
                  closeDeactivateLocationModal();
                }}
                color={"black"}
              >
                Close
              </Button>

              <Button
                colorScheme="red"
                onClick={() => {
                  handleLocationStatus("inactive");
                  closeDeactivateLocationModal();
                }}
              >
                Deactivate
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={activateLocationIsOpen}
          onClose={() => {
            setIdToChangeStatus(null);
            closeActivateLocationModal();
          }}
          onOpen={openActivateLocationModal}
          isCentered
        >
          <ModalOverlay />

          <ModalContent maxW="436px">
            <ModalHeader fontWeight={700} fontSize={"18px"}>
              Activate Location
            </ModalHeader>
            <ModalCloseButton onClick={() => setIdToDelete(null)} />
            <ModalBody>
              <Text color="#319795" fontSize={"16px"}>
                Activating this location will make it accessible again for
                various activities.
              </Text>
              <Text color="#319795" fontSize={"16px"} mt={"16px"}>
                Are you sure you want to proceed and activate this location?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="modalsLight"
                mr={3}
                onClick={() => {
                  setIdToChangeStatus(null);
                  closeActivateLocationModal();
                }}
                color={"black"}
              >
                Close
              </Button>

              <Button
                colorScheme="modalsDark"
                onClick={() => {
                  handleLocationStatus("active");
                  closeActivateLocationModal();
                }}
              >
                Activate
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
};

export default AddClientLocation;
