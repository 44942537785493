import React, { useEffect, useState } from "react";
import SelectElements from "../../../Components/selectElements";
import Editable from "../../../Components/Editable";
import {
  HStack,
  ButtonGroup,
  Box,
  Text,
  Switch,
  FormLabel,
  Badge,
  CloseButton,
  VStack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Input, Button } from "@agnext/reactlib";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import { useDispatch } from "react-redux";
import { actions } from "../../Create/slice";
import Option from "../Option";
import { generateId } from "../../../../../utils/index";
import FileProperties from "views/Workflow Builder/Components/fileProperties";
import Repeatable from "views/Workflow Builder/Components/Repeatable";

export default function Element({
  elementId,
  componentId,
  elementOrder,
  elementOrderLast,
  currElement,
  sectionId,
  isValid,
  validationState,
  errorStyle,
  repeatableDisabled,
}) {
  const elementValidationState = validationState.elements?.find(
    (element) => element.id === elementId
  );

  const [element, setElement] = useState({
    value: "",
    label: "",
  });

  const [mandatoryStatus, setMandatoryStatus] = useState(false);

  const [repeatable, setRepeatable] = useState({
    isRepeatable: false,
    unlimitedEntries: false,
    minEntries: 1,
    maxEntries: 1,
  });
  const [repeatableModalValidation, setRepeatableModalValidation] = useState(
    null
  );

  const [elementName, setElementName] = useState();

  const [supportingText, setSupportingText] = useState("Supporting Text");

  const [placeholder, setPlaceholder] = useState("Placeholder");

  const [href, setHref] = useState("");

  const [showAddOption, setShowAddOption] = useState(false);

  const [showAddRange, setShowAddRange] = useState(false);

  const [showFileOptions, setShowFileOptions] = useState(false);
  const [showDomainOption, setShowDomainOption] = useState(false);
  const [option, setOption] = useState({
    value: "",
    label: "",
  });

  const [range, setRange] = useState({
    from: "",
    to: "",
  });
  const [rangeErrorMessage, setRangeErrorMessage] = useState(null);

  const [fileOptions, setFileOptions] = useState({
    maxFileCount: 1,
    maxFileSize: 10,
    allowedFileTypes: [],
  });

  const [emailDomain, setEmailDomain] = useState("");
  const [showTextValidationOption, setShowTextValidationOption] = useState(false);
  const [textValidationState, setTextValidationState] = useState({
    min: "",
    max: "",
    isNumbersAllowed: false,
    isSpecialCharAllowed: false,
  });
  const [isMinMaxValid, setIsMinMaxValid] = useState(true);

  useEffect(() => {
    setElement({ value: currElement.htmlType || "" });
    setMandatoryStatus(currElement.mandatory);
    setRepeatable(currElement.repeatable);
    setElementName(currElement.label || "Element Label");
    setPlaceholder(
      currElement.placeholder ? currElement.placeholder : "Placeholder"
    );
    setSupportingText(currElement.supportingText || "Supporting Text");

    const {
      minCharLength,
      maxCharLength,
      isNumbersAllowed,
      isSpecialCharAllowed,
    } = currElement?.properties || {};
    setTextValidationState((prev) => ({
      ...prev,
      ...(minCharLength && { min: minCharLength }),
      ...(maxCharLength && { max: maxCharLength }),
      ...(isNumbersAllowed !== undefined && { isNumbersAllowed }),
      ...(isSpecialCharAllowed !== undefined && { isSpecialCharAllowed }),
    }));
  }, [currElement]);

  const dispatch = useDispatch();

  const handleElementNameChange = (value) => {
    setElementName(value);
  };

  const handleplaceHolderChange = (value) => {
    setPlaceholder(value);
  };

  const handleSupportingTextChange = (value) => {
    setSupportingText(value);
  };

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    const newRange = { ...range, [name]: value.trim() };
    setRange(newRange);
  };

  const handleElementChange = (element) => {
    setElement(element);
    dispatch(
      actions.editElementType({
        elementId,
        htmlType: element.value,
        componentId,
      })
    );
  };

  const handleMandatoryStatusChange = () => {
    dispatch(
      actions.editMandatoryStatusElement({
        elementId,
        componentId,
        mandatory: !mandatoryStatus,
      })
    );
    setMandatoryStatus(!mandatoryStatus);
  };

  const handleRepeatableSubmit = (onClose) => {
    const {
      isRepeatable,
      unlimitedEntries,
      minEntries,
      maxEntries,
    } = repeatable;
    if (!isRepeatable || unlimitedEntries || minEntries < maxEntries) {
      dispatch(
        actions.editRepeatableElement({
          elementId,
          componentId,
          repeatable,
        })
      );
      onClose();
      setRepeatableModalValidation(null);
    } else {
      setRepeatableModalValidation(
        "Please ensure that minEntries is less than to maxEntries."
      );
    }
  };

  const handleRepeatableChange = (key, value) => {
    setRepeatable((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleOptionValueChange = (e) => {
    setOption((prevState) => {
      return { ...prevState, value: e.target.value };
    });
  };

  const handleOptionLabelChange = (e) => {
    setOption((prevState) => {
      return { ...prevState, label: e.target.value };
    });
  };
  const handleOptionChange = (value) => {
    setFileOptions({
      ...fileOptions,
      allowedFileTypes: [`${value}`],
    });
  };

  const handleCountChange = (e, key) => {
    const newValue = e.target.value;
    setFileOptions((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };
  const handleElementNameSubmit = (elementName) => {
    const payload = {
      elementId,
      componentId,
      elementName,
    };
    dispatch(actions.editElementLabel(payload));
  };

  const handleSupportingTextSubmit = (text) => {
    const payload = {
      elementId,
      componentId,
      text,
    };
    dispatch(actions.editElementText(payload));
  };

  const handleRangeSubmit = () => {
    if (range.from && range.to) {
      if (Number(range.from) < Number(range.to)) {
        const payload = {
          elementId,
          componentId,
          range,
        };
        dispatch(actions.editElementRange(payload));
        setRange({ from: "", to: "" });
        setRangeErrorMessage(null);
      } else {
        setRangeErrorMessage("'From' value should be less than 'To' value");
        return;
      }
    } else {
      return;
    }
  };

  const handleHrefSubmit = () => {
    const payload = {
      elementId,
      componentId,
      href,
    };
    dispatch(actions.editElementHref(payload));
  };

  const handleOptionSubmit = () => {
    if (!option.label || !option.value) return;
    dispatch(actions.addElementOptions({ option, elementId, componentId }));
    setOption({
      value: "",
      label: "",
    });
  };

  const handleDomainSubmit = () => {
    if (emailDomain.trim()) {
      const payload = {
        elementId,
        componentId,
        emailDomain,
      };
      dispatch(actions.editElementEmailDomain(payload));
      setEmailDomain("");
    }
  };
  const handleRemoveDomain = (index) => {
    const payload = {
      elementId,
      componentId,
      index,
    };
    dispatch(actions.removeElementEmailDomain(payload));
  };

  const handleCharLength = (e) => {
    setIsMinMaxValid(true);
    const { value, name } = e.target;
    if (name === "minChar") {
      const payload = {
        elementId,
        componentId,
        value,
      };
      dispatch(actions.editTextElementMinCharLength(payload));
      setTextValidationState((prev) => ({
        ...prev,
        min: value,
      }));
    } else if (name === "maxChar") {
      const payload = {
        elementId,
        componentId,
        value,
      };
      dispatch(actions.editTextElementMaxCharLength(payload));
      setTextValidationState((prev) => ({
        ...prev,
        max: value,
      }));
    }
  };

  const handleAllowedSpecialChar = () => {
    const payload = {
      elementId,
      componentId,
      value: !textValidationState?.isSpecialCharAllowed,
    };
    dispatch(actions.isSpecialCharAllowedForElement(payload));
    setTextValidationState((prev) => ({
      ...prev,
      isSpecialCharAllowed: !textValidationState?.isSpecialCharAllowed,
    }));
  };

  const handleAllowedNumbers = () => {
    const payload = {
      elementId,
      componentId,
      value: !textValidationState?.isNumbersAllowed,
    };
    dispatch(actions.isNumbersAllowedForElement(payload));
    setTextValidationState((prev) => ({
      ...prev,
      isNumbersAllowed: !textValidationState?.isNumbersAllowed,
    }));
  };

  const minMaxValidation = () => {
    const { min, max } = textValidationState;
    if (min && max && Number(min) >= Number(max)) {
      setIsMinMaxValid(false);
      return "Min value should be less than Max value";
    }
    return true;
  };

  const handlePlaceholderSubmit = (placeholder) => {
    const payload = {
      elementId,
      componentId,
      placeholder,
    };
    dispatch(actions.editElementPlaceHolder(payload));
  };

  const handleFileOptionsSubmit = () => {
    dispatch(
      actions.addFileProperties({ fileOptions, elementId, componentId })
    );
  };

  const moveUpHandlerElement = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.moveUpElement(payload));
  };

  const handleElementDelete = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.deletionElement(payload));
  };

  const handleElementDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      componentId,
      elementId,
    };
    dispatch(actions.duplicateElement(payload));
  };

  const moveDownHandlerElement = () => {
    const payload = {
      componentId,
      elementId,
    };
    dispatch(actions.moveDownElement(payload));
  };

  useEffect(() => {
    const elementType = element.value;
    if (
      elementType === "radio" ||
      elementType === "checkbox" ||
      elementType === "dropdown" ||
      elementType === "checkboxSearch" ||
      elementType === "dropdownWithSearch"
    ) {
      setShowAddOption(true);
    } else {
      setShowAddOption(false);
    }
    if (elementType === "numberInput") {
      setShowAddRange(true);
    } else {
      setShowAddRange(false);
    }
    if (elementType === "fileUpload") {
      setShowFileOptions(true);
      setFileOptions(currElement.fileOptions);
    } else {
      setShowFileOptions(false);
    }
    if (elementType === "email") {
      setShowDomainOption(true);
    } else {
      setShowDomainOption(false);
    }
    if (elementType === "textInput" || elementType === "textarea") {
      setShowTextValidationOption(true);
    } else {
      setShowTextValidationOption(false);
    }
  }, [element]);

  const showRepeatOptionFor = [
    "textInput",
    "numberInput",
    "textarea",
    "datepicker",
    "email",
    "mobile",
  ];

  return (
    <>
      <Box
        w={"100%"}
        style={{
          padding: "16px",
          border: "1px solid #EFEFEF",
          margin: "16px 0 16px 0",
          borderLeft: "2px solid rgba(84, 3, 117, 0.5)",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <HStack w="100%" style={{ padding: "16px" }} justify="space-between">
          <Box
            w="30%"
            sx={!isValid && !elementValidationState?.label ? errorStyle : {}}
          >
            <Editable
              text={elementName}
              handleTextChange={handleElementNameChange}
              handleSubmit={handleElementNameSubmit}
            />
          </Box>
          <HStack w="15%" justify="center">
            <Switch
              isChecked={mandatoryStatus}
              id="mandatory"
              colorScheme="login"
              onChange={handleMandatoryStatusChange}
            />
            <FormLabel color="login.label" htmlFor="remember" mb="0">
              Mandatory
            </FormLabel>
          </HStack>
          {element.value === "textInput" ||
          element.value === "textarea" ||
          element.value === "numberInput" ||
          element.value === "singleFileUpload" ||
          element.value === "multiFileUpload" ? (
            <Box w="20%">
              <Editable
                text={placeholder}
                handleTextChange={handleplaceHolderChange}
                handleSubmit={handlePlaceholderSubmit}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box
            w="20%"
            sx={!isValid && !elementValidationState?.htmlType ? errorStyle : {}}
          >
            <SelectElements
              element={element}
              onElementChange={handleElementChange}
            />
          </Box>
          <ButtonGroup justifyContent="center" size="md">
            <Moveup
              moveUpHandler={moveUpHandlerElement}
              isDisabled={elementOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerElement}
              isDisabled={elementOrder === elementOrderLast ? true : false}
            />
            <Delete deleteHandler={handleElementDelete} />
            <Duplicate duplicateHandler={handleElementDuplicate} />
            <Repeatable
              isFor="Element"
              repeatable={repeatable}
              handleChange={handleRepeatableChange}
              handleSubmit={handleRepeatableSubmit}
              repeatableModalValidation={repeatableModalValidation}
              disabled={{
                repeatableDisabled,
                notApplicableforThisElement: !showRepeatOptionFor.includes(
                  element?.value
                ),
                isNotSelected: !element?.value,
              }}
            />
          </ButtonGroup>
        </HStack>
        <HStack w="70%" gap="16px" style={{ paddingLeft: "16px" }}>
          <Editable
            text={supportingText}
            handleTextChange={handleSupportingTextChange}
            handleSubmit={handleSupportingTextSubmit}
          />
        </HStack>
        {/* {Range} */}
        {showAddRange ? (
          <>
            {currElement.range ? (
              <HStack w="30%" justify="space-between" padding="16px">
                <HStack>
                  <Text>From: </Text>
                  <Text color="login.500">{currElement.range.from}</Text>
                </HStack>
                <HStack>
                  <Text>To: </Text>
                  <Text color="login.500">{currElement.range.to}</Text>
                </HStack>
              </HStack>
            ) : (
              <></>
            )}
            <HStack>
              <HStack w="50%" justify="space-between" paddingLeft="16px">
                <HStack>
                  <Input
                    name="from"
                    variant="flushed"
                    value={range.from}
                    placeholder="From"
                    onChange={handleRangeChange}
                    onInput={(e) => {
                      const inputValue = e.target.value;
                      const decimalCount = (inputValue.match(/\./g) || [])
                        .length;
                      if (decimalCount <= 1) {
                        e.target.value = inputValue.replace(/[^0-9.]/g, "");
                      } else {
                        e.target.value = inputValue.replace(
                          /[.](?=.*[.])/g,
                          ""
                        );
                      }
                    }}
                  />
                </HStack>
                <HStack>
                  <Input
                    name="to"
                    variant="flushed"
                    value={range.to}
                    placeholder="To"
                    onChange={handleRangeChange}
                    onInput={(e) => {
                      const inputValue = e.target.value;
                      const decimalCount = (inputValue.match(/\./g) || [])
                        .length;
                      if (decimalCount <= 1) {
                        e.target.value = inputValue.replace(/[^0-9.]/g, "");
                      } else {
                        e.target.value = inputValue.replace(
                          /[.](?=.*[.])/g,
                          ""
                        );
                      }
                    }}
                  />
                </HStack>
                <Button
                  label="Add range"
                  onClick={handleRangeSubmit}
                  colorScheme="login"
                  size="sm"
                  borderRadius="8px"
                />
              </HStack>
              <Text m="10px" color="red" fontSize="14px">
                {rangeErrorMessage && rangeErrorMessage}
              </Text>
            </HStack>
          </>
        ) : (
          <></>
        )}
        {element.value === "link" ? (
          <HStack w="70%" gap="16px" style={{ paddingLeft: "16px" }}>
            <Text>href</Text>
            <Input
              value={href}
              onChange={(e) => setHref(e.target.value)}
              variant="flushed"
              focusBorderColor="login.500"
              style={{ padding: "4px 16px" }}
            />
            <Button
              colorScheme="login"
              label="Add"
              size="sm"
              borderRadius="8px"
              onClick={handleHrefSubmit}
            />
          </HStack>
        ) : (
          <></>
        )}
        {showAddOption ? (
          <>
            <HStack
              gap="32px"
              sx={
                !isValid && !elementValidationState?.options ? errorStyle : {}
              }
            >
              <Box w="30%" style={{ paddingLeft: "16px" }}>
                <Text color="login.500">Value</Text>
              </Box>
              <Box w="30%" style={{ paddingLeft: "16px" }}>
                <Text color="login.500">Label</Text>
              </Box>
            </HStack>
            <HStack gap="32px">
              <Box w="30%">
                <Input
                  variant="flushed"
                  focusBorderColor="login.500"
                  style={{ padding: "4px 16px" }}
                  value={option.value}
                  onChange={handleOptionValueChange}
                />
              </Box>
              <Box w="30%">
                <Input
                  variant="flushed"
                  focusBorderColor="login.500"
                  style={{ padding: "4px 16px" }}
                  value={option.label}
                  onChange={handleOptionLabelChange}
                />
              </Box>
              <Box>
                <Button
                  size="lg"
                  onClick={handleOptionSubmit}
                  colorScheme="login"
                  leftIcon={<AddIcon />}
                  variant="ghost"
                  borderRadius="8px"
                />
              </Box>
            </HStack>
            <>
              {currElement.properties &&
                currElement.properties.options &&
                currElement.properties.options.map((option, index) => (
                  <Option
                    key={index}
                    value={option.value}
                    label={option.label}
                    element={currElement}
                    componentId={componentId}
                    sectionId={sectionId}
                    navigationDisabled={repeatableDisabled}
                  />
                ))}
            </>
          </>
        ) : (
          <></>
        )}
        {showFileOptions ? (
          <>
            <FileProperties
              fileOptions={fileOptions}
              handleOptionChange={handleOptionChange}
              handleCountChange={handleCountChange}
              handleFileOptionsSubmit={handleFileOptionsSubmit}
              isValid={isValid}
              validationFileOptions={elementValidationState?.fileOptions}
              errorStyle={errorStyle}
            />
          </>
        ) : (
          <></>
        )}
        {showDomainOption ? (
          <>
            {currElement?.properties?.emailDomains?.length ? (
              <HStack w="90%" justify="flex-start" padding="16px">
                <Text>Domain: </Text>
                {currElement?.properties?.emailDomains?.map((val, index) => (
                  <Badge
                    key={index}
                    display="flex"
                    alignItems="center"
                    textTransform="none"
                  >
                    {val}
                    <CloseButton
                      size="sm"
                      onClick={() => handleRemoveDomain(index)}
                    />
                  </Badge>
                ))}
              </HStack>
            ) : (
              <></>
            )}
            <HStack w="50%" justify="space-between" paddingLeft="16px">
              <HStack>
                <Input
                  name="domain"
                  variant="flushed"
                  value={emailDomain}
                  placeholder="Enter Domain"
                  onChange={(e) => setEmailDomain(e.target.value.toLowerCase())}
                />
              </HStack>
              <Button
                label="Add Domain"
                onClick={handleDomainSubmit}
                colorScheme="login"
                size="sm"
                borderRadius="8px"
              />
            </HStack>
          </>
        ) : (
          <></>
        )}
        {showTextValidationOption ? (
          <VStack alignItems="flex-start" p="16px" gap="16px">
            <HStack gap="24px">
              <HStack gap="8px">
                <Text>Allow Numbers:</Text>
                <Switch
                  isChecked={textValidationState?.isNumbersAllowed}
                  id="mandatory"
                  colorScheme="login"
                  onChange={handleAllowedNumbers}
                />
              </HStack>
              <HStack gap="8px">
                <Text>Allowed Special Characters:</Text>
                <Switch
                  isChecked={textValidationState?.isSpecialCharAllowed}
                  id="mandatory"
                  colorScheme="login"
                  onChange={handleAllowedSpecialChar}
                />
              </HStack>
            </HStack>
            <VStack>
              <HStack gap="16px">
                <Text>Minimum Character Length:</Text>
                <Input
                  sx={!isValid && elementValidationState?.properties?.minCharLength === false ? errorStyle : {}}
                  name="minChar"
                  variant="flushed"
                  placeholder="Enter Length"
                  value={textValidationState?.min}
                  onChange={handleCharLength}
                  onInput={(e) => {
                    const inputValue = e.target.value;
                    const decimalCount = (inputValue.match(/\./g) || []).length;
                    if (decimalCount <= 1) {
                      e.target.value = inputValue.replace(/[^0-9.]/g, "");
                    } else {
                      e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
                    }
                  }}
                  onBlur={minMaxValidation}
                />
              </HStack>
              <HStack gap="16px">
                <Text>Maximum Character Length:</Text>
                <Input
                  sx={!isValid && elementValidationState?.properties?.maxCharLength === false ? errorStyle : {}}
                  name="maxChar"
                  variant="flushed"
                  placeholder="Enter Length"
                  value={textValidationState?.max}
                  onChange={handleCharLength}
                  onInput={(e) => {
                    const inputValue = e.target.value;
                    const decimalCount = (inputValue.match(/\./g) || []).length;
                    if (decimalCount <= 1) {
                      e.target.value = inputValue.replace(/[^0-9.]/g, "");
                    } else {
                      e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
                    }
                  }}
                  onBlur={minMaxValidation}
                />
              </HStack>
              {isMinMaxValid === false ? ( 
                <Text m="10px" color="red" fontSize="14px" textAlign="flex-end">
                  Min value should be less than Max value
                </Text>
              ) : null}
            </VStack>
          </VStack>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
