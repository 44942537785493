import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { useParams } from "react-router-dom";
import { FormDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import HeaderGray from "views/Workflow/components/headerGray";
import { VStack, Box, useMediaQuery } from "@chakra-ui/react";
import Section from "./section";
import { convertToCamelCase, generateUUId } from "utils/utils";
import _ from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";

function hasPageNumber(arr, pageNumber) {
  let res = -1;
  arr.forEach((el, i) => {
    if (el.pageNumber === pageNumber) {
      res = i;
    }
  });
  return res;
}

function ViewForm(props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: FormDataSaga });

  const dispatch = useDispatch();
  const { workflowId, formId } = useParams();

  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({});

  const [displayData, setDisplayData] = useState([]);

  const [navIndex, setNavIndex] = useState(null);

  const [page, setPage] = useState(0);

  const [previewData, setPreviewData] = useState([]);

  const handleFilesChange = (file) => {
    const newFiles = [...files];
    newFiles.push(file);
    setFiles(newFiles);
  };

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");

  const workflow = useSelector(selectors.selectWorkflow);

  let sections = useSelector(selectors.selectSections);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const getElementsState = useCallback(() => {
    const elements = componentsForElements.reduce((acc, cv) => {
      const elementsCurr = cv.structure.elements.map((element) => element);
      elementsCurr.forEach((currEl) => {
        acc[currEl.id] = {
          title: currEl.label,
          name: convertToCamelCase(currEl.label),
          value: "",
          attachments: [],
          itemType: currEl.htmlType,
          mimeType: "NA",
          itemOrder: currEl.order,
          parentId: cv.id,
          parentType: "component",
          parentInfo: {
            id: cv.id,
            title: cv.title,
          },
          id: generateUUId(),
          formId: workflow[0].id,
          templateItemId: currEl.id,
          itemInfo: currEl.properties ? currEl.properties : {},
          inspectionId: workflow[0].inspectionId,
          mandatory: currEl.mandatory,
          tableName: "inspectionItems",
        };
      });
      return acc;
    }, {});
    return elements;
  }, [componentsForElements]);

  const getElements = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        componentForElements.structure.elements.forEach(
                          (element) => {
                            elementDisplayData.push({
                              blockId: blockForComposites.id,
                              element,
                            });
                          }
                        );
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  const getDisplayData = useCallback(() => {
    let displayData = [];
    if (sectionsForBlocks) {
      sectionsForBlocks.forEach((section, index) => {
        displayData.push({
          pageNumber: section?.order,
          sections: section?.structure?.blocks || [],
          pageId: section.id,
        });
      });
    }
    return displayData;
  }, [sectionsForBlocks]);

  const getComponents = useCallback(
    (blockId) => {
      let elementDisplayData = [];
      if (
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        blocksForComposites.forEach((blockForComposites) => {
          blockForComposites.structure.composites.forEach((composite) => {
            compositesForComponents.forEach((compositeForComponents) => {
              if (composite.id === compositeForComponents.id) {
                compositeForComponents.structure.components.forEach(
                  (component) => {
                    componentsForElements.forEach((componentForElements) => {
                      if (componentForElements.id === component.id) {
                        elementDisplayData.push({
                          blockId: blockForComposites.id,
                          component: componentForElements,
                        });
                      }
                    });
                  }
                );
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.blockId === blockId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [blocksForComposites, compositesForComponents, componentsForElements]
  );

  // some code is commented because it is not used right now but may be in the future
  // const handleFormDataChange = (e, val, id, mimeType, files) => {
  //   if (_.isArray(val)) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: {
  //         ...prevState[id],
  //         attachments: [...val],
  //         files,
  //         mimeType,
  //       },
  //     }));
  //     return;
  //   }
  //   if (_.isArray(e)) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: {
  //         ...prevState[id],
  //         attachments: e,
  //         mimeType,
  //       },
  //     }));
  //     return;
  //   }
  //   if (_.isString(e) || _.isDate(e)) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: {
  //         ...prevState[id],
  //         value: e,
  //       },
  //     }));
  //     return;
  //   }
  //   if (val && id) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [id]: {
  //         ...prevState[id],
  //         value: val,
  //       },
  //     }));
  //     return;
  //   }
  //   const { name, value, checked } = e.target;
  //   if (!val) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [name]: {
  //         ...prevState[name],
  //         value: value || value === "" || value === 0 ? value : checked,
  //       },
  //     }));
  //   } else {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       [name]: {
  //         ...prevState[name],
  //         value: val,
  //       },
  //     }));
  //   }
  // };

  let getPreviewData = useCallback(() => {
    if (_.isArray(sections)) {
      let data = [...sections].sort((a, b) => {
        return a.order - b.order;
      });
      setPreviewData(data);
    }
  }, [sections]);

  useEffect(() => {
    console.log("fetchWorkflowData")
    dispatch(
      actions.fetchWorkflowData({ id: props.formId ? props.formId : formId })
    );

    return () => {
      dispatch(actions.clearState());
    };
  }, [dispatch, formId, props.formId]);

  console.log({ displayData });

  useEffect(() => {
    console.log("getPreviewData")
    getPreviewData();
  }, [sections]);

  useEffect(() => {
    console.log("getDisplayData")
    setDisplayData(getDisplayData());
  }, [getDisplayData]);

  useEffect(() => {
    console.log("getElementsState")
    setFormData(getElementsState());
  }, [getElementsState]);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <VStack
        mt={props?.marginTop ? props?.marginTop : "75px"}
        w="100%"
        gap="36px"
      >
        <Box w="100%">
          <HeaderGray form={workflow && workflow[0]} hidePagesDetails={true} />
          <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
            <VStack
              w={isMobileScreen ? "100%" : props.width ? props.width : "740px"}
              style={{
                margin: "0 auto",
                padding: "64px 24px",
              }}
              gap="32px"
              area-label="sections"
              justify="flex-start"
              align="flex-start"
            >
              {displayData &&
                displayData.length &&
                displayData?.sort((a, b) => a.pageNumber - b.pageNumber).map((pageData) =>
                  pageData.sections.map((section) => {
                    return (
                      <Section
                        key={section.id}
                        section={section}
                        data={getComponents(section.id)}
                        formData={formData}
                        handleFormDataChange={()=>{}}
                        displayData={displayData}
                        setPage={setPage}
                        navIndex={navIndex}
                        setNavIndex={setNavIndex}
                        page={page}
                      />
                    );
                  })
                )}
            </VStack>
          </Box>
        </Box>
      </VStack>
    </ErrorBoundary>
  );
}

export default function View(props) {
  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <ViewForm {...props} />
    </ErrorBoundary>
  );
}
