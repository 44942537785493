import {
  Box,
  Container,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Edit2 from "assets/svg/edit2.svg";
import location from "assets/svg/location.svg";
import dateRange from "assets/svg/dateRange.svg";
import buisnessLogo from "assets/svg/Busisness Type.svg";
import defaultAvatar from "assets/img/avatars/defaultAvatar.png";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import { Link } from "react-router-dom";
import { FiX } from "react-icons/fi";

const ProfileWithName = ({ customerAvatar }) => {
  return (
    <Box
      background={"#EDF2F7"}
      height={"57px"}
      width={"57px"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: "pointer", overflow:"hidden" }}
      borderRadius={"15px"}
    >
      <Image src={customerAvatar?.url || defaultAvatar} alt="" />
    </Box>
  );
};

const Dot = ({ status }) => {
  const bg = status ? "#4FD1C5" : "red";
  return (
    <Box
      style={{
        width: "7px",
        height: "7px",
        background: bg,
        borderRadius: "50%",
      }}
    />
  );
};

const statusText = {
  color: "#718096",
  fontSize: "14px",
  fontWeight: "400",
  wordWrap: "break-word",
  padding: "2px",
  paddingTop: "3px",
  textTransform: "capitalize",
  alignItems: "center",
};

const outerBox = {
  display: "flex",
  justifyContent: "space-between",
  background: "linear-gradient(91deg, #FFF 39.14%, #B6FFF8 263.21%), #FFF",
  padding: "24px 20px",
  borderRadius: "15px",
};

const editButtonStyle = {
  background: "teal.400",
  height: "40px",
  width: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
};

const SvgAndText = ({ src, text }) => {
  return (
    <Flex align="center" gap={"1px"}>
      <Box>
        <Image src={src} alt="Dan Abramov" />
      </Box>
      <Text
        style={{
          color: "#718096",
          fontSize: "16px",
          fontWeight: "400",
          wordWrap: "break-word",
          padding: "4px",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};
// const {
//   avatar,
//   businessType,
//   creationDate,
//   isActive,
//   name,
//   registrationInfo,
//   creationDt,
//   updateDt,
// } =  customerDetails;
const ViewCustomerTop = ({
  customerDetails,
  addressArray,
  customerAvatar,
  customerId,
}) => {
  return (
    <Box style={outerBox}>
      <Flex width="100%" gap="16px">
        <ProfileWithName customerAvatar={customerAvatar} />
        <Flex flex="1" direction="column" gap="8px">
          <Flex lineHeight="25.2px" gap="8px" alignItems="center">
            {customerDetails && customerDetails?.name ? (
              <Text
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  color: "#2D3748",
                }}
              >
                {customerDetails?.name}
              </Text>
            ) : (
              "-"
            )}
            <Flex alignItems="center">
              <Flex alignItems="center" h="inherit" px="8.5px">
                <Dot status={customerDetails?.isActive} />
              </Flex>

              <Text sx={statusText}>
                {customerDetails?.isActive ? "Active" : "Inactive"}
              </Text>
            </Flex>
          </Flex>
          <Stack
            direction="row"
            align="center"
            height="100%"
            gap="24px"
            h="24px"
          >
            <SvgAndText
              src={location}
              text={
                addressArray && addressArray[0]?.country
                  ? addressArray[0]?.country
                  : "-"
              }
            />
            {/* <SvgAndText
              src={buisnessLogo}
              text={customerDetails?.businessType || ""}
            /> */}
            <SvgAndText
              src={dateRange}
              text={
                customerDetails && customerDetails?.creationDt
                  ? extractDayMonthYearFromTimestamp(
                      customerDetails?.creationDt
                    )
                  : "-"
              }
            />
          </Stack>
        </Flex>
        <Link to={`/admin/agency/updateAgency?agencyId=${customerId}`}>
          <Box sx={editButtonStyle}>
            <Image src={Edit2} alt="" />
          </Box>
        </Link>
      </Flex>
    </Box>
  );
};

export default ViewCustomerTop;
