import React, { useEffect, useState } from "react";
import { HStack, Input, IconButton, VStack, Text } from "@chakra-ui/react";
import { HiMenuAlt4 } from "react-icons/hi";
import { Button } from "@agnext/reactlib";
import { AddIcon, WarningIcon } from "@chakra-ui/icons";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { validateText } from "utils/helper";

export default function ShortAnswer({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
}) {
  const properties = data?.element?.properties;
  if (data.element.repeatable?.isRepeatable || repeatable?.isRepeatable) {
    const minEntries = data.element.repeatable?.minEntries;
    const maxEntries = data.element.repeatable?.maxEntries;
    const unlimitedEntries = data.element.repeatable?.unlimitedEntries;

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
          isValidShortAnswer: {isValid: true, message: ""}
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
        isValidShortAnswer: {isValid: true, message: ""}
      });
    }

    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
              isValidShortAnswer: {isValid: true, message: ""}
            })),
          ]
        : initialValues
    );
    const handleAddMore = () => {
      if (
        !values[values.length - 1].value ||
        (values.length >= maxEntries && !unlimitedEntries)
      )
        return;
      setValues((prevValues) => [
        ...prevValues,
        { order: prevValues[prevValues.length - 1].order + 1, value: "", isValidShortAnswer: {isValid: true, message: ""}},
      ]);
    };

    const handleDelete = (order) => {
      if (values.length < 2 || values.length <= minEntries) return;
      const newVals = values.filter((value) => value.order !== order);
      setValues(newVals);
      handleFormDataChange(data.element.id, "repeatable-delete", order - 1);
    };

    const elementData = formData[data.element.id]?.attachments;
    useEffect(() => {
      if (elementData?.length) {
        setValues(
          elementData.map((value, index) => ({
            order: index + 1,
            value,
            isValidShortAnswer: validateText(value, properties)
          }))
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            { target: { name: data.element.id, value: value.value } },
            "repeatable",
            value.order
          );
        });
      }
    }, [elementData]);

    if (repeatable?.isRepeatable) {
      const value = values[index];
      return (
        <VStack w="100%">
          <HStack w="100%" mb="8px">
            <HStack
              w="100%"
              h="48px"
              style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
            >
              <HStack w="48px" h="48px" justify="center" align="center">
                <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
              </HStack>
              <Input
                type="text"
                variant="unstyled"
                placeholder={data.element.placeholder || "Short Answer"}
                required={data.element.mandatory}
                color="#2D3748"
                _placeholder={{ color: "#A0AEC0" }}
                name={data.element.id}
                value={elementData[index]}
                onChange={(e) => {
                  handleFormDataChange(e, "repeatable", index + 1);
                }}
              />
            </HStack>
          </HStack>
          {value?.isValidShortAnswer?.isValid === false ? (
            <ErrorMessage message={value?.isValidShortAnswer?.message} />
          ) : null}
        </VStack>
      );
    }

    return (
      <>
        {values &&
          values.map((value, i) => (
            <VStack w="100%">
              <HStack w="100%" mb="8px" key={value.order}>
                <HStack
                  w="100%"
                  h="48px"
                  style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
                >
                  <HStack w="48px" h="48px" justify="center" align="center">
                    <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
                  </HStack>
                  <Input
                    type="text"
                    variant="unstyled"
                    placeholder={data.element.placeholder || "Short Answer"}
                    required={data.element.mandatory}
                    color="#2D3748"
                    _placeholder={{ color: "#A0AEC0" }}
                    name={data.element.id}
                    value={elementData[i]}
                    onChange={(e) => {
                      handleFormDataChange(e, "repeatable", value.order);
                    }}
                  />
                </HStack>
                <IconButton
                  variant="ghost"
                  borderRadius="8px"
                  isDisabled={values.length < 2 || values.length <= minEntries}
                  sx={{
                    ".icon": {
                      color: "red.500",
                    },
                  }}
                  icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                  onClick={() => {
                    handleDelete(value.order);
                  }}
                />
              </HStack>
              {value?.isValidShortAnswer?.isValid === false ? (
                <ErrorMessage message={value?.isValidShortAnswer?.message} />
              ) : null}
            </VStack>
          ))}
        <Button
          size="sm"
          borderRadius="8px"
          onClick={handleAddMore}
          leftIcon={<AddIcon />}
          label="Add More"
          isDisabled={
            !values[values.length - 1].value ||
            (values.length >= maxEntries && !unlimitedEntries)
          }
        />
      </>
    );
  }

  const [isValidShortAnswer, setIsValidShortAnswer] = useState({ isValid: true, message: "" })
  return (
    <VStack w="100%">
      <HStack
        w="100%"
        h="48px"
        style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
      >
        <HStack w="48px" h="48px" justify="center" align="center">
          <HiMenuAlt4 size={18} style={{ fill: "#718096" }} />
        </HStack>
        <Input
          type="text"
          variant="unstyled"
          placeholder={data.element.placeholder}
          required={data.element.mandatory}
          color="#2D3748"
          _placeholder={{ color: "#A0AEC0" }}
          name={data.element.id}
          value={formData[data.element.id]?.value}
          onChange={(e) => {
            handleFormDataChange(e);
            setIsValidShortAnswer(validateText(e.target.value, properties));
          }}
        />
      </HStack>
      {isValidShortAnswer?.isValid === false ? (
        <ErrorMessage message={isValidShortAnswer?.message} />
      ) : null}
    </VStack>
  );
}

function ErrorMessage({message}) {
  return (
    <HStack w="100%" justify="flex-start" align="center" mb="20px">
      <WarningIcon color="#D93025" />
      <Text
        aria-label="element label"
        color="#D93025"
        fontSize="14px"
        fontWeight="400"
        lineHeight="1.4"
        textAlign="center"
      >
        {message}
      </Text>
    </HStack>
  );
}