import React, { useState } from "react";
import {
  Box,
  HStack,
  Text,
  Select,
  IconButton,
  Flex,
  CircularProgress,
  VStack,
} from "@chakra-ui/react";
import { LineChart } from "components/Charts/LineChart";
import BarChart from "components/Charts/BarChart";
import { LineChartIcon } from "components/Icons/Icons";
import { v4 as id } from "uuid";
import noRecordImage from "../../../../../assets/img/Empty Case.png";

const styles = {
  barChartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderRadius: "15px",
    border: "1px solid #E2E8F0",
    background: " #FFF",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  },
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",

    alignItems: "flex-start",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
    alignItems: "center",
    height: "100%",
  },
  chartHeaderBtn: {
    padding: "0px 12px",
    justifyContent: "center",
    height: "32px",
    alignItems: "center",
    border: "1px solid #EDF2F7",
    color: "#4A5568",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  },
  barChartSelectOpt: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  barChart: {
    width: "100%",
  },
  barChartSelect: {
    borderRadius: "8px",
    width: "206px",
    border: "1px solid #E2E8F0",
    background: "#F7FAFC",
    display: "flex",
    height: "32px",
    padding: " 6px 8px 6px 12px",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "12px",
    color: "#4A5568",
    fontSize: "14px",
  },
  message: {
    display: "flex",
    width: "100%",
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "190px",
    margin: "auto",
  },
};


export default function InspectionCount({
  selectedDateType,
  handleDateType,
  disabledDateTypes,
  inspectionCountData,
  isDropdownVisible,
  isFor,
}) {
  const [isLineChartVisible, setIsLineChartVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "Inspection Count",
    value: "inspectionsCount",
  });

  const handleDataTypeChange = (event) => {
    const newValue = event.target.value;
    const selectedIndex = event.target.selectedIndex;
    const selectedOptionLabel = event.target.options[selectedIndex].label;

    setSelectedOption({
      label: selectedOptionLabel,
      value: newValue,
    });
  };
  const toggleChart = () => {
    setIsLineChartVisible(!isLineChartVisible);
  };

  const { barChartData, data, labels, loading } = inspectionCountData || {
    barChartData: null,
    data: null,
    labels: null,
    loading: false,
  };
  let modifiedData;
  if (data && data.length) {
    modifiedData = data.map((item) => {
      if (item[selectedOption.value]) {
        return parseInt(item[selectedOption.value]);
      }
    });
  }
  return (
    <Box
      sx={styles.barChartContainer}
      padding={{ base: "16px", md: "20px" }}
      w="100%"
      gap={barChartData && barChartData?.length ? "36px" : "0px"}
    >
      <HStack
        sx={styles.headerContainer}
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: "16px", md: "inherit" }}
      >
        <Flex sx={styles.header}>{selectedOption.label}</Flex>
        {barChartData && barChartData?.length ? (
          <HStack>
            <IconButton
              bg="#F7FAFC"
              _hover={{ cursor: "pointer" }}
              _active={{}}
              sx={{ height: "32px", borderRadius: "8px" }}
              icon={<LineChartIcon />}
              onClick={toggleChart}
            ></IconButton>
            <HStack gap={"0px"}>
              <Box
                sx={styles.chartHeaderBtn}
                borderBottomLeftRadius="8px"
                borderTopLeftRadius="8px"
                as="button"
                backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
                onClick={() => {
                  handleDateType("day");
                }}
              >
                D
              </Box>
              <Box
                sx={{
                  ...styles.chartHeaderBtn,
                  cursor: disabledDateTypes?.includes("week")
                    ? "not-allowed"
                    : "pointer",
                }}
                as="button"
                backgroundColor={selectedDateType === "week" ? "#F7FAFC" : ""}
                onClick={() => {
                  handleDateType("week");
                }}
                disabled={disabledDateTypes?.includes("week")}
              >
                W
              </Box>
              <Box
                sx={{
                  ...styles.chartHeaderBtn,
                  cursor: disabledDateTypes?.includes("month")
                    ? "not-allowed"
                    : "pointer",
                }}
                as="button"
                borderBottomRightRadius="8px"
                borderTopRightRadius="8px"
                backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
                onClick={() => {
                  handleDateType("month");
                }}
                disabled={disabledDateTypes?.includes("month")}
              >
                M
              </Box>
            </HStack>

            {/* Use the below variable to view or hide dropdown */}
            {isDropdownVisible ? (
              <Select
                sx={styles.barChartSelect}
                onChange={handleDataTypeChange}
                value={selectedOption.value}
              >
                <option
                  value="inspectionsCount"
                  sx={styles.barChartSelectOpt}
                >
                  Inspection Count
                </option>
                <option value="noOfBags" sx={styles.barChartSelectOpt}>
                  Bag Count
                </option>
                <option value="quantity" sx={styles.barChartSelectOpt}>
                  Quantity Inspected (MT)
                </option>
              </Select>
            ) : (
              <></>
            )}
          </HStack>
        ) : (
          <></>
        )}
      </HStack>
      <Box
        sx={styles.barChart}
        h={barChartData && barChartData?.length ? "270px" : "auto"}
      >
        {loading ? (
          <Flex
            sx={{
              justifyContent: "center",
              height: "inherit",
              alignItems: "center",
            }}
          >
            <CircularProgress
              isIndeterminate
              color="#4FD1C5"
              size="45px"
              alignSelf={"center"}
            />
          </Flex>
        ) : barChartData &&
          barChartData?.length &&
          modifiedData &&
          modifiedData.length ? (
          isLineChartVisible ? (
            <LineChart
              labels={labels}
              data={modifiedData}
              key={id()}
              showLine={true}
              tooltipData={barChartData}
              isFor={isFor}
            />
          ) : (
            <BarChart
              labels={labels}
              barData={modifiedData}
              tooltipData={barChartData}
              isFor={isFor}
            />
          )
        ) : (
          <VStack style={styles.message}>
            <img
              style={styles.image}
              src={noRecordImage}
              alt="No Records Image"
            />
            <p>No Data Available</p>
          </VStack>
        )}
      </Box>
    </Box>
  );
}
